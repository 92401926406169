import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useContainers = () => {
  const [containers, setContainers] = useState([]);
  const [loading, setloading] = useState(false);
  // const [search, setSearch] = useState(false);
  // function changeboolvalue() {
  //   setSearch(false);
  // }

  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setContainers(data["containers"]);
    setloading(false);
    console.log("data");
    console.log(data);
  };

  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "remove_container",
    fetchData
  );

  return {
    t,
    navigate,
    fetchData,
    containers,
    loading,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  };
};

export default useContainers;
