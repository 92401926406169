import React from "react";
import axios from "axios";

class FileUpload extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedFile: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  editUser() {
    const data = new FormData();
    console.log("data11");
    console.log(data);
    data.append("image", this.state);
    data.append("name", document.getElementById("name").value);
    data.append("email", document.getElementById("email").value);
    data.append("gender", document.getElementById("gender").value);
    data.append("phone", document.getElementById("phone").value);
    data.append("date", document.getElementById("date").value);
    console.log("this.state");
    console.log(this.state);
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" };
    axios
      .post("https://app.medical-clinic.tk/api/auth/user/update", data, { headers })
      .then((response) => {
        //   handleClickOpenSuccess();
        console.log(response);
        console.log("success");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //   submit() {
  //     const data = new FormData();
  //     data.append("file", this.state);
  //     console.warn(this.state);
  //     const url = "https://movies.ps/anas_jweles_backend/anas_backend/upload.php";

  //     axios
  //       .post(url, data, {
  //         // receive two parameter endpoint url ,form data
  //       })
  //       .then((res) => {
  //         // then print response status
  //         console.warn(res);
  //       });
  //   }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <br />
            <br />

            <h3 className="text-white">React File Upload - Nicesnippets.com</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-6">
                {/* <label className="text-white">Select File :</label> */}
                <input
                  type="file"
                  className="form-control"
                  name="upload_file"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <button type="submit" className="btn btn-dark" onClick={() => this.editUser()}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
