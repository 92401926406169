/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  // Paper,
} from "@mui/material";
import { CCol, CForm, CFormInput, CFormSelect, CRow } from "@coreui/react";
import useOrderFromExcel from "hooks/orders/useOrderFromExcel";
import AddOrderFromExcelDialog from "./excel_dialog";

function AddOrderFromExcel() {
  const {
    customer,
    setCustomer,
    customers,
    container,
    setContainer,
    containers,
    statusarray,
    shopnumber1,
    shopnumber2,
    setShopNumber2,
    setShopNumber1,
    phone,
    setPhone,
    deliverydate,
    setDeliveryDate,
    file,
    handleFileChange,
    validated,
    handleSubmit,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    loading,
  } = useOrderFromExcel();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol md={3}>
                  <CFormSelect
                    id="productCategory"
                    label="customer"
                    feedbackInvalid="cusotmer required"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    required
                  >
                    <option selected="" value="">
                      {" "}
                      Choose customer ...
                    </option>

                    {customers.map((user) => (
                      <option value={user.code}>{user.name}</option>
                    ))}
                  </CFormSelect>
                </CCol>
                <CCol md={3}>
                  <CFormInput
                    type="text"
                    placeholder="shop number"
                    feedbackInvalid="shop number is required"
                    id="categoryshopnumberAr"
                    label="shop number"
                    required
                    onChange={(e) => {
                      setShopNumber2(e.target.value);
                    }}
                    value={shopnumber2}
                  />
                </CCol>
                <CCol md={3}>
                  <CFormInput
                    type="text"
                    placeholder="order code"
                    feedbackInvalid="order code is required"
                    id="categoryordercodeAr"
                    label="order code"
                    required
                    onChange={(e) => setOrderNumber(e.target.value)}
                    value={customer + shopnumber2}
                  />
                </CCol>
                <CRow>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="phone number"
                      feedbackInvalid="phone number is required"
                      id="categoryphonenumberAr"
                      label="phone number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="date"
                      placeholder="delivery date"
                      feedbackInvalid="delivery date is required"
                      id="categorydeliverydateAr"
                      label="delivery date"
                      required
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      value={deliverydate}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormSelect
                      id="productCategory"
                      label="container"
                      feedbackInvalid="Container required"
                      value={container}
                      onChange={(e) => setContainer(e.target.value)}
                      required
                    >
                      <option selected="" value="">
                        {" "}
                        Choose container ...
                      </option>

                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>
                </CRow>
              </CRow>
            </CForm>
            {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} /> */}
            <div style={{ width: "1000px", margin: "20px" }}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Order code
                    </label>
                    <input
                      required
                      id="shop_no1"
                      onChange={(e) => setShopNumber1(e.target.value)}
                      value={customer + shopnumber2}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Shop code"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Shop Number
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Shop Number"
                      onChange={(e) => setShopNumber2(e.target.value)}
                      value={shopnumber2}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Customer
                    </label>
                    <select
                      id="customer_id"
                      onChange={(e) => setCustomer(e.target.value)}
                      value={customer}
                      className="form-control"
                      style={{ height: "40px" }}
                    >
                      {customers.map((user) => (
                        <option value={user.code}>{user.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Phone No.
                    </label>
                    <input
                      id="phone_number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Phone No."
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Delivery Date
                    </label>
                    <input
                      id="delivery_date"
                      required
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      value={deliverydate}
                      type="date"
                      className="form-control"
                      name="name"
                      placeholder="Delivery Date"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Containers
                    </label>
                    <select
                      id="customer_id"
                      onChange={(e) => setContainer(e.target.value)}
                      value={container}
                      className="form-control"
                      style={{ height: "40px" }}
                    >
                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      {""}
                    </label>
                  </div>

                  <div style={{ position: "relative", display: "inline-block", marginTop: "15px" }}>
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      name="name"
                      onChange={handleFileChange}
                      id="customFileInput"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="customFileInput"
                      style={{
                        display: "inline-block",
                        padding: "5px 10px",
                        backgroundColor: "#17c1e8",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Choose File
                    </label>
                  </div>
                </div>
                <AddOrderFromExcelDialog
                  customer_id={customer}
                  container_id={container}
                  phone_number={phone}
                  order_number={customer + shopnumber2}
                  delivery_date={deliverydate}
                  file={file}
                />
              </div>
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddOrderFromExcel;
