import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

function DeleteDialog({ dialogdelete, closeDialog, deleteItem }) {
  const { t } = useTranslation();
  DeleteDialog.propTypes = {
    dialogdelete: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
  };
  return (
    <Dialog
      open={dialogdelete}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("delete_post_sure")}</DialogTitle>
      <DialogActions>
        {/* <Button onClick={handleClose}>ok</Button> */}
        <Button
          onClick={() => {
            deleteItem();
          }}
          autoFocus
        >
          {t("yes")}
        </Button>
        <Button
          onClick={() => {
            closeDialog();
          }}
          autoFocus
        >
          {t("no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
