/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import CardHome from "layouts/home_screen/card_home";

/* eslint-disable jsx-a11y/img-redundant-alt */
const SinglePost = () => {
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [postID, setPostID] = useState("");
  const navigate = useNavigate();
  const imagesContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end", // Align images to the right
    marginTop: "10px",
  };

  const imageStyle = {
    maxWidth: "200px", // Adjust the width as needed
    height: "200px",
    borderRadius: "10px",
    margin: "5px", // Add spacing between images
    objectFit: "cover",
  };

  const route = useLocation().pathname.split("/").slice(1);
  const [posts, setPosts] = useState([]);

  const fetchData = async () => {
    const response = await fetch("https://trust-cargo.co/anas_backend/api/posts");
    const data = await response.json();
    setPosts(data["posts"]);
  };

  const fetchPostData = async () => {
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/get_specefic_post/${route[route.length - 1]}`
    );
    const data = await response.json();

    setDescription(data["post"]["body"]);
    if (data.images_post.length > 0) {
      const imageUrls = data.images_post.map((imageData) => imageData.image);
      setImages(imageUrls);
    }
    setPostID(data["post"]["id"]);
  };

  useEffect(() => {
    fetchPostData();
    fetchData();
  }, []);
  const filteredPosts = posts.filter((post) => post.id !== postID);

  console.log("Original Posts:", posts);
  console.log("Filtered Posts:", filteredPosts);
  console.log(postID);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Welcome To Anas Jweiles
          </Typography>
          <Button
            onClick={() => {
              navigate("/authentication/sign-in");
            }}
            color="inherit"
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>{" "}
      <div
        onClick={() => window.history.back()}
        style={{
          color: "#17c1e8",
          cursor: "pointer",
          marginTop: "20px",
          marginLeft: "20px",
          margin: "5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-arrow-left-square"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
        </svg>
      </div>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className="mx-auto" style={{ display: "flex", width: "1100px", marginTop: "30px" }}>
          <div className="">
            <div className="container">
              <div className="">
                <div className="col-lg-12">
                  <div className="">
                    <h4 className="m-4" style={{ fontSize: "20px" }}>
                      {description}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="col-lg-7" style={imagesContainerStyle}>
            {images.map((image, index) => (
              <img key={index} src={image} alt={`Image ${index}`} style={imageStyle} />
            ))}
          </div>
        </div>
      </div>
      <div
        className=" mx-auto"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "1100px",
          marginTop: "70px",
        }}
      >
        {/* <a className="d-block"> */}
        {filteredPosts.slice(0, posts.length).map((data) => (
          <div className=" mx-auto">
            <div>
              <div className="axil-img-container m-b-xs-15 m-b-sm-30">
                <a>
                  {/* <a className="d-block"> */}

                  <div
                    className="image-list-item"
                    style={{ height: "250px", width: "200px" }}
                    onClick={() => navigate(`/single-post/${data.id}`)}
                  >
                    <img
                      src={data.images[0].image}
                      alt=""
                      className="img-fluid"
                      width="100px"
                      height="100%"
                      // style={{ height: "390" }}
                    />
                  </div>

                  <div className="grad-overlay grad-overlay__transparent" />
                  {/* </a> */}
                </a>
                <div className="media post-block grad-overlay position-absolute">
                  <div className="media-body justify-content-end">
                    <div
                      className="post-cat-group m-b-xs-10"
                      onClick={() => navigate(`/single-post/${data.id}`)}
                    >
                      <a>
                        <a className={`post-cat cat-btn btn-mid bg-color-blue-one`}></a>
                      </a>
                    </div>
                    <div
                      className="axil-media-bottom"
                      onClick={() => navigate(`/single-post/${data.id}`)}
                    >
                      <h4
                        className=" hover-line m-b-xs-0"
                        style={{ fontSize: "20px", color: "white", cursor: "pointer" }}
                      >
                        <a style={{ textDecoration: "none" }}>
                          {data.body.length > 30 ? `${data.body.substring(0, 30)}...` : data.body}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>

                {/* End of .post-block */}
              </div>
            </div>{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SinglePost;
