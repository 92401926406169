/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import axios from "axios";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/users/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import EditPostComplete from "./edit_User1";

function EditPost() {
  const route = useLocation().pathname.split("/").slice(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState([]);
  const [image, setImage] = useState([""]);

  const fetchPosts = async () => {
    console.log(route[route.length - 1]);

    const headers = { ContentType: `application/json` };
    const response = await fetch(
      ` https://trust-cargo.co/anas_backend/api/get_specefic_post/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["post"]["body"]);
    if (data.images_post.length > 0) {
      setImage(data.images_post[0].image);
    }
    console.log("name", image);
  };

  useEffect(() => {
    // fetchData();
    fetchPosts();
  }, []);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">{t("edit_user")}</SuiTypography>
            </SuiBox> */}
            <div style={{ width: "200px", marginLeft: "30px", color: "" }}>
              <div
                className="form-group  col-md-12 "
                style={{ width: "200px", marginLeft: "10px", color: "" }}
              >
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Name
                </label>
                <textarea
                  required
                  type=""
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>

              <div style={{ width: "200px", margin: "15px", color: "" }}>
                <div className="form-group  col-md-12 ">
                  {/* {photo[index] && (
                  <img
                    src={photo[index] ? URL.createObjectURL(photo[index]) : ""}
                    width={100}
                    style={{ position: "absolute", top: 0, left: 0, height: "120px" }}
                  />
                )} */}
                  {image ? <img src={image} alt="image" width={200} /> : <></>}
                </div>
              </div>

              {/* <Box
                sx={{
                  width: "50rem",
                  height: "3rem",
                  border: 1,
                  borderColor: "#D3D6DA",
                  borderRadius: "8px",
                  margin: "15px",
                  justify: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{
                    alignContent: "center",
                    marginLeft: "10px",
                    maxWidth: "100px",
                    maxHeight: "2rem",
                    minWidth: "100px",
                    minHeight: "2rem",
                  }}
                >
                  {t("upload_image")}
                  <input type="file" value={selectedFile} />
                </Button> */}
              {/* </Box> */}
              {/* <EditPostComplete /> */}
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditPost;
