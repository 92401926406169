/* eslint-disable camelcase */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

export default function EditCustomerSuccess({
  name1,
  code1,
  address1,
  vat_name1,
  phone1,
  user_id1,
}) {
  EditCustomerSuccess.propTypes = {
    name1: PropTypes.string.isRequired,
    code1: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    vat_name1: PropTypes.string.isRequired,
    phone1: PropTypes.string.isRequired,
    user_id1: PropTypes.string.isRequired,
  };
  const route = useLocation().pathname.split("/").slice(1);
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };

  function editPatient() {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .post(
        "https://trust-cargo.co/anas_backend/api/edit_customer",
        {
          id: route[route.length - 1],
          name: name1,
          code: code1,
          phone_number: phone1,
          address: address1,
          vat_name: vat_name1,
          user_id: user_id1,
        },
        { headers }
      )
      .then((response) => {
        console.log(response);
        console.log("success");
        handleClickOpenSuccess();
        // navigate(`/patients`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px" }}
        onClick={() => {
          handleClickOpenLoading();
          editPatient();
        }}
      >
        {t("save")}
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم تعديل هذا الزبون بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate(`/customers`);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
