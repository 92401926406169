/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ReactLoading from "react-loading";

// import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
// import Select from "@mui/material/Select";

import { CCol, CForm, CFormInput, CFormSelect, CRow } from "@coreui/react";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useCustomerAdding from "hooks/customers/useCustomerAdding";
import { Spinner } from "react-bootstrap";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

function AddCustomer() {
  const {
    name,
    setName,
    phone,
    setPhone,
    code,
    setCode,
    address,
    setAddress,
    vat_name,
    setVatname,
    user,
    setUser,
    loading,
    setLoading,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    handleSubmit,
    validated,
    users,
  } = useCustomerAdding();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="name"
                    feedbackInvalid="name is required"
                    id="categoryNameAr"
                    label="name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="code"
                    feedbackInvalid="code is required"
                    id="categorycodeAr"
                    label="code"
                    required
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    value={code}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="phone number"
                    feedbackInvalid="phone number is required"
                    id="categoryphone numberAr"
                    label="phone number"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    value={phone}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="address"
                    feedbackInvalid="address is required"
                    id="categoryaddressAr"
                    label="address"
                    required
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="Vat name"
                    feedbackInvalid="Vat name is required"
                    id="categoryVat nameAr"
                    label="Vat name"
                    required
                    onChange={(e) => {
                      setVatname(e.target.value);
                    }}
                    value={vat_name}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormSelect
                    id="productCategory"
                    label="status"
                    feedbackInvalid="Status required"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    required
                  >
                    <option selected="" value="">
                      {" "}
                      Choose user ...
                    </option>

                    {users.map((user1) => (
                      <option value={user1.id}>{user1.name}</option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              {loading ? (
                <ReactLoading color="blue" height={50} width={50} type="spokes" />
              ) : (
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white", width: "15%" }}
                  type="submit"
                >
                  <span style={{ fontWeight: "bolder" }}>Add Customer</span>
                </Button>
              )}
            </CForm>
            <div style={{ width: "400px", margin: "20px" }}>
              {/* <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Name
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Code
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Phone Number
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Address
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Vat Name
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setVatname(e.target.value)}
                  value={vat_name}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px", color: "black" }}
                >
                  Choose User
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setUser(e.target.value)}
                >
                  {users.map((user1) => (
                    <option value={user1.id}>{user1.name}</option>
                  ))}
                </select>
              </div> */}
            </div>
            <Dialog
              open={dialogSuccess}
              onClose={() => {
                setDialogSuccess(false);
                navigate("/customers");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Customer Added Successfully</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    navigate("/customers");
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogError}
              onClose={() => {
                setDialogError(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Adding customer failed</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogError(false);
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddCustomer;
