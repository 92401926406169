/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import axios from "axios";
import { ImagePicker } from "react-file-picker";
// Data
import projectsTableData from "layouts/users/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingDialog from "./dialog";
import FileUpload from "./image";
// import { AppImage } from "./image";

function EditCurrentUser() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [gender, setGender] = useState([]);
  const [password, setPassword] = useState([]);
  const [email, setEmail] = useState([]);
  const [date, setDate] = useState([]);
  const fetchUsers = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch("https://app.medical-clinic.tk/api/auth/user", { headers });
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    console.log("data");
    console.log(data);
    setName(data["name"]);
    setPhone(data["phone"]);
    setEmail(data["email"]);
    setGender(data["gender"]);
    setDate(data["birthdate"]);
  };

  const changeName = (event) => {
    setName(event.target.value);
  };
  const changePhone = (event) => {
    setPhone(event.target.value);
  };
  const changeGender = (event) => {
    setGender(event.target.value);
  };
  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeDate = (event) => {
    setDate(event.target.value);
  };
  const changePassword = (event) => {
    setPassword(event.target.value);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    fetchUsers();
  }, []);
  const { columns: prCols, rows: prRows } = projectsTableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">{t("edit_user")}</SuiTypography>
            </SuiBox>
            <div style={{ width: "200px", marginLeft: "20px", color: "green" }}>
              <TextField
                id="name"
                onChange={changeName}
                value={name}
                // label={t("user_name")}
                variant="outlined"
                margin="dense"
                // InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  style: {
                    fontSize: 16,
                    height: "2rem",
                    minWidth: "50rem",
                    width: "50rem",
                  },
                }}
                style={{ width: "50rem", fontSize: "15px", margin: "15px" }}
              />
              <FileUpload />
              <Box
                sx={{
                  width: "50rem",
                  height: "3rem",
                  border: 1,
                  borderColor: "#D3D6DA",
                  borderRadius: "8px",
                  margin: "15px",
                  justify: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{
                    alignContent: "center",
                    marginLeft: "10px",
                    maxWidth: "100px",
                    maxHeight: "2rem",
                    minWidth: "100px",
                    minHeight: "2rem",
                  }}
                >
                  {t("upload_image")}
                  <input type="file" value={selectedFile} onChange={changeHandler} hidden />
                </Button>
              </Box>
              <TextField
                id="phone"
                onChange={changePhone}
                value={phone}
                // label={t("phone")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  style: {
                    minWidth: "50rem",
                    width: "50rem",
                    fontSize: 16,
                    height: "2rem",
                  },
                }}
                style={{ width: "50rem", fontSize: "15px", margin: "15px" }}
              />
              <TextField
                id="email"
                // label={t("email")}
                onChange={changeEmail}
                value={email}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  style: {
                    minWidth: "50rem",
                    width: "50rem",
                    fontSize: 16,
                    height: "2rem",
                  },
                }}
                style={{ width: "50rem", fontSize: "15px", margin: "15px" }}
              />
              {/* <TextField
                id="password"
                // label={t("password")}
                onChange={changePassword}
                value={password}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  style: {
                    fontSize: 16,
                    height: "2rem",
                  },
                }}
                style={{ width: "50rem", fontSize: "15px", margin: "15px" }}
              /> */}
              <TextField
                id="date"
                onChange={changeDate}
                value={date}
                // label={t("birthdate")}
                type="date"
                // defaultValue="2017-05-24"
                sx={{ width: 220, marginTop: "20px", marginBottom: "20px" }}
                inputProps={{
                  style: {
                    minWidth: "50rem",
                    width: "50rem",
                    height: "2rem",
                  },
                }}
                style={{ width: "50rem", margin: "15px" }}
              />
              <Box
                sx={{
                  width: "50rem",
                  height: "3rem",
                  border: 1,
                  borderColor: "#D3D6DA",
                  borderRadius: "8px",
                  margin: "15px",
                  justify: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <select
                  id="gender"
                  className="form-control"
                  style={{ height: "3rem" }}
                  onChange={changeGender}
                  value={gender}
                >
                  <option value="male">{t("male")}</option>
                  <option value="female">{t("female")}</option>
                </select>
              </Box>
              {/* <Box
                sx={{
                  width: "50rem",
                  height: "3rem",
                  border: 1,
                  borderColor: "#D3D6DA",
                  borderRadius: "8px",
                  margin: "15px",
                  justify: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <select id="user_type" className="form-control" style={{ height: "3rem" }}>
                  <option value="admin">{t("admin")}</option>
                  <option value="user">{t("secertary")}</option>
                </select>
              </Box> */}
              <LoadingDialog />
            </div>
            <SuiTypography
              onClick={() => {
                navigate("/edit_current_user_password");
              }}
              variant="h6"
              style={{ margin: "30px" }}
            >
              Do you want to change password?
            </SuiTypography>
          </Card>
        </SuiBox>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCurrentUser;
