/* eslint-disable dot-notation */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
// import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import Multiselect from "multiselect-react-dropdown";
import useMessageAdding from "hooks/messages/useMessageAdding";
import ReactLoading from "react-loading";
import { CCol, CForm, CFormInput, CFormSelect, CRow } from "@coreui/react";

function AddMessage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    customer,
    setCustomer,
    customers,
    body,
    setBody,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    handleSubmit,
    validated,
  } = useMessageAdding();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="body"
                    feedbackInvalid="body is required"
                    id="categorybodyAr"
                    label="body"
                    required
                    onChange={(e) => {
                      setBody(e.target.value);
                    }}
                    value={body}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormSelect
                    id="productCategory"
                    label="status"
                    feedbackInvalid="Status required"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    required
                  >
                    <option selected="" value="">
                      {" "}
                      Choose customer ...
                    </option>

                    {customers.map((cusotmer) => (
                      <option value={cusotmer.id}>{cusotmer.name}</option>
                    ))}
                  </CFormSelect>
                </CCol>
              </CRow>
              {loading ? (
                <ReactLoading color="blue" height={50} width={50} type="spokes" />
              ) : (
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white", width: "15%" }}
                  type="submit"
                >
                  <span style={{ fontWeight: "bolder" }}>Add Message</span>
                </Button>
              )}
            </CForm>
            <Dialog
              open={dialogSuccess}
              onClose={() => {
                setDialogSuccess(false);
                navigate("/messages");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Message Added Successfully</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    navigate("/messages");
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogError}
              onClose={() => {
                setDialogError(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Adding message failed</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogError(false);
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddMessage;
