/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Button,
  InputAdornment,
} from "@mui/material";
// import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import AlertDialog from "./dialog_success";

function AddTransaction() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rows, setRows] = useState([0]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const getOrderNumbers = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/orders", {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["orders"]);
  };
  const getUsers = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/users", {
      headers,
    });
    const data = await response.json();
    console.log("data");
    console.log(data);
    // eslint-disable-next-line dot-notation
    setUsers(data["accounts"]);
  };
  const [ordersnumber1, setOrdersNumbers] = useState([]);
  const getOrders = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/orders");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrdersNumbers(data["orders"]);
  };
  useEffect(() => {
    // getCoins();
    getUsers();
    getOrderNumbers();
    getOrders();
  }, []);
  const [name, setName] = useState();
  const [ordernumber, setOrderNumber] = useState([""]);
  const [notes, setNotes] = useState([""]);
  const [usernumber, setUserNumber] = useState([""]);
  const [accountname, setAccountName] = useState([""]);
  const [debet, setDebet] = useState([""]);
  const [credit, setCredit] = useState([""]);
  const [balance, setBalance] = useState([""]);
  const [currency, setCurrency] = useState([""]);
  const [valcurrency, setValCurrency] = useState([""]);

  const handleOrderNumberChange = (event, index) => {
    setOrderNumber((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleAccountChange = (event, index) => {
    setAccountName((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDebetChange = (event, index) => {
    setDebet((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCreditChange = (event, index) => {
    setCredit((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCurrencyChange = (event, index) => {
    setCurrency((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleValCurrwncyChange = (event, index) => {
    setValCurrency((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleBalanceChange = (event, index) => {
    setBalance((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleUserNumberChange = (event, index) => {
    const selctedUser = users.find((e) => e["user_number"] === event.target.value);
    // console.log("selctedUser");
    // console.log(event.target.value);
    // console.log(selctedUser);
    setAccountName((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? selctedUser["name"] : el;
      })
    );
    setCurrency((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? selctedUser["currency"] : el;
      })
    );
  };
  const edebet = debet.map((e, index) => e * valcurrency[index]);
  const ecredit = credit.map((e, index) => e * valcurrency[index]);
  const balance1 = edebet.map((e, index) => e - ecredit[index]);

  const total_edebet = edebet.reduce((e1, e2) => e1 + e2);
  const total_ecredit = ecredit.reduce((e1, e2) => e1 + e2);
  const total_balance = balance1.reduce((e1, e2) => e1 + e2);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Transactions</SuiTypography>
            </SuiBox>
            <div style={{ width: "200px", margin: "20px", color: "green" }}>
              <AlertDialog
                name1={ordernumber}
                account_name1={accountname}
                debet1={debet}
                credit1={credit}
                e_debet1={edebet}
                e_credit1={ecredit}
                currency1={currency}
                val_currency1={valcurrency}
                balance1={balance1}
                totalbalance1={total_balance}
                notes1={notes}
              />
            </div>
            <div className="row" style={{ marginLeft: "20px" }}>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "20px" }}
                  >
                    Total E-Debet
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    placeholder="Total Amount"
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={total_edebet}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "20px" }}
                  >
                    Total E-Credit
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    placeholder="Total Amount"
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={total_ecredit}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "20px" }}
                  >
                    Total Balance
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    placeholder="Total Balance"
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={total_balance}
                  />
                </div>
              </div>
            </div>

            <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ display: "flex" }}>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Order No.
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      User.Number
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Acc.Name
                    </TableCell>
                  </Box>

                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Debet
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Credit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Currency
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Val-Curr
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      E-Debit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      E-Credit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Balance
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Notes
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Operations
                    </TableCell>
                  </Box>
                </TableRow>
              </TableHead>
              <TableBody style={{ display: "block " }}>
                {rows.map((user, index) => (
                  <TableRow key={`${index}key`} sx={{ minWidth: "100px" }}>
                    <Box style={{ overflowX: "auto" }}>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="desc1"
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Order Number"
                          onChange={(e) => handleOrderNumberChange(e, index)}
                          value={ordernumber[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <select
                          id="shop"
                          className="form-control"
                          style={{ height: "40px" }}
                          onChange={(e) => handleUserNumberChange(e, index)}
                        >
                          {users?.map((e) => (
                            <option value={e.user_number}>
                              {e.user_number} -{e.name}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="desc1"
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Account Name"
                          onChange={(e) => handleAccountChange(e, index)}
                          value={accountname[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="desc2"
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Debet"
                          onChange={(e) => handleDebetChange(e, index)}
                          value={debet[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          id="category"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Credit"
                          onChange={(e) => handleCreditChange(e, index)}
                          value={credit[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Currency"
                          onChange={(e) => handleCurrencyChange(e, index)}
                          value={currency[index]}
                          id="ctn"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Val Currency"
                          onChange={(e) => handleValCurrwncyChange(e, index)}
                          value={valcurrency[index]}
                          id="qtytoctn"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="E-Debet"
                          onChange={(e) => handleBalanceChange(e, index)}
                          value={edebet[index]}
                          id="ttoqty"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="E-Credit"
                          onChange={(e) => handleBalanceChange(e, index)}
                          value={ecredit[index]}
                          id="utoprice"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Balance"
                          readOnly
                          // onChange={(e) => handleBalanceChange(e, index)}
                          value={balance1[index]}
                          // id="amount"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Notes"
                          onChange={(e) => handleNotesChange(e, index)}
                          value={notes[index]}
                          // id="amount"
                        />
                      </TableCell>

                      <TableCell
                        sx={{ width: "400px", minWidth: "400px" }}
                        style={{ flexDirection: "row" }}
                        align="space-around"
                      >
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            width: "100px",
                            minWidth: "100px",
                            fontSize: "10px",
                          }}
                          onClick={() => {
                            setOrderNumber((state) => [...state, ""]);
                            setUserNumber((state) => [...state, ""]);
                            setAccountName((state) => [...state, ""]);
                            setDebet((state) => [...state, ""]);
                            setCredit((state) => [...state, ""]);
                            setCurrency((state) => [...state, ""]);
                            setValCurrency((state) => [...state, ""]);
                            setBalance((state) => [...state, ""]);
                            setNotes((state) => [...state, ""]);
                            setRows((current) => [...current, current.length]);
                          }}
                        >
                          Add Item
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            width: "120px",
                            fontSize: "10px",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
                            setOrderNumber((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setUserNumber((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setAccountName((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setDebet((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setCredit((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setCurrency((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setValCurrency((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setBalance((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setNotes((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                          }}
                        >
                          Remove Item
                        </Button>
                      </TableCell>
                    </Box>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </TableContainer> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddTransaction;
