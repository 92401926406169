import useContainers from "hooks/containers/useContainers";
import useCustomers from "hooks/customers/useCustomers";
import useFormValidation from "hooks/general/useFormValidation";
import useStatus from "hooks/status/useStatus";
import { useState } from "react";
import axios from "axios";

const useOrderFromExcel = () => {
  const [customer, setCustomer] = useState("");
  const [container, setContainer] = useState("");
  const { customers } = useCustomers();
  const { status: statusarray } = useStatus();
  const { containers } = useContainers();
  const [shopnumber1, setShopNumber1] = useState("");
  const [shopnumber2, setShopNumber2] = useState("");
  const [phone, setPhone] = useState("");
  const [deliverydate, setDeliveryDate] = useState("");
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const [orderNumber, setOrderNumber] = useState();

  function addOrder() {
    setLoading(true);
    setOrderNumber(customer + shopnumber2);
    const formdata = new FormData();
    formdata.append("delivery_date", deliverydate);
    formdata.append("phone_number", phone);
    formdata.append("order_code", customer + shopnumber2);
    formdata.append("customer_id", customer);
    formdata.append("container_id", container);
    formdata.append("file_order_details", file);
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/uplode_order";
    console.log("file", file);
    axios
      .post(url, formdata, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { validated, handleSubmit } = useFormValidation(addOrder);

  return {
    customer,
    setCustomer,
    customers,
    container,
    setContainer,
    containers,
    statusarray,
    shopnumber1,
    shopnumber2,
    setShopNumber2,
    setShopNumber1,
    phone,
    setPhone,
    deliverydate,
    setDeliveryDate,
    file,
    handleFileChange,
    validated,
    handleSubmit,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    loading,
    orderNumber,
  };
};

export default useOrderFromExcel;
