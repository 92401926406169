/* eslint-disable no-dupe-keys */
/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useState } from "react";
import PropTypes, { string } from "prop-types";

export default function AlertDialog({
  ItemArray,
  id,
  photo,
  desc1,
  desc2,
  category,
  ctn,
  qtytoctn,
  totoqty,
  utoprice,
  amount,
  cbm,
  ttocbm,
  nw,
  gw,
  status,
  notes,
  debet1,
  credit1,
  // shop_number1,
  // shop_number2,

  phone_number,
  total_amount1,
  customer_id,
  delivery_date,
  order_number,
}) {
  AlertDialog.propTypes = {
    ItemArray: PropTypes.instanceOf(Array).isRequired,
    id: PropTypes.instanceOf(string).isRequired,
    photo: PropTypes.instanceOf(Array).isRequired,
    desc1: PropTypes.instanceOf(Array).isRequired,
    desc2: PropTypes.instanceOf(Array).isRequired,
    category: PropTypes.instanceOf(Array).isRequired,
    ctn: PropTypes.instanceOf(Array).isRequired,
    qtytoctn: PropTypes.instanceOf(Array).isRequired,
    totoqty: PropTypes.instanceOf(Array).isRequired,
    utoprice: PropTypes.instanceOf(Array).isRequired,
    amount: PropTypes.instanceOf(Array).isRequired,
    cbm: PropTypes.instanceOf(Array).isRequired,
    ttocbm: PropTypes.instanceOf(Array).isRequired,
    nw: PropTypes.instanceOf(Array).isRequired,
    gw: PropTypes.instanceOf(Array).isRequired,
    status: PropTypes.instanceOf(Array).isRequired,
    notes: PropTypes.instanceOf(Array).isRequired,
    // shop_number1: PropTypes.string.isRequired,
    // shop_number2: PropTypes.string.isRequired,

    phone_number: PropTypes.string.isRequired,
    debet1: PropTypes.string.isRequired,
    total_amount1: PropTypes.string.isRequired,
    credit1: PropTypes.string.isRequired,
    customer_id: PropTypes.string.isRequired,
    delivery_date: PropTypes.string.isRequired,
    order_number: PropTypes.string.isRequired,
  };

  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };

  const [shoponeArray, setshoponeArray] = useState([1, 2, 5, 6, 6, 4, 4]);
  // const [name, setName] = useState([]);
  // const onNameChange = (e, index) => {
  //   setName((state) => {
  //     return state.map((el, index1) =>
  //        index === index1 ? e.target.value : el;)

  //   });
  // };
  function addOrderTest() {
    console.log("customer_id");
    console.log(customer_id);
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/edit_order";

    axios
      .post(
        url,
        {
          id: id,
          name: ItemArray,
          photo: ItemArray,
          description1: desc1,
          description2: desc2,
          category: category,
          ctn: ctn,
          amount: amount,
          cbm: cbm,
          status: status,
          notes: notes,
          qty_ctn: qtytoctn,
          t_qty: totoqty,
          u_price: utoprice,
          t_cbm: ttocbm,
          n_w: nw,
          g_w: gw,
          shop_no1: "safsad",
          shop_no2: "saasasd",
          phone_number: phone_number,
          total_amount: total_amount1.toString(),
          debet_account: debet1,
          credit_account: credit1,
          notes: "notes",
          order_number: order_number,
          delivery_date: "2022-09-07",
          customer_id: customer_id.toString(),
        },
        headers
      )
      .then((response) => {
        // console.log("success");
        // console.log(formData);
        handleCloseLoading();
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="contained"
        style={{ color: "white", marginTop: "20px" }}
        onClick={() => {
          handleClickOpenLoading();
          if (phone_number === "") {
            handleCloseLoading();
            handleClickOpenEmpty();
          } else {
            addOrderTest();
          }
        }}
      >
        Confirm Order
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم تأكيد الطلبيه بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* navigate("/add_user"); */}
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/orders");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("add_lab_empty")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
