/* eslint-disable prettier/prettier */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      janauary: "Janauary ",
      febreuery: "Febreuery 2",
      march: "March 3",
      april: "April 4",
      may: "May 5",
      june: "June 6",
      july: "July 7",
      august: "August 8",
      september: "September 9 ",
      octuber: "Octuber 10",
      november: "November 11",
      december: "December 12",
      logout_success: "You have been successfully logged out",
      satarday: "Satarday",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wedensday: "Wedensday",
      thursday: "Thursday",
      friday: "Friday",
      users: "Users",
      patients: "Patients",
      user: "User",
      reservations: "Reservations",
      drugs: "Drugs",
      lab_tests: "Lab Tests",
      expenses: "Expenses",
      clinics: "Clinics",
      xray: "X-Ray",
      dashboard: "Dashboard",
      instructions: "Instructions",
      description: "Description",
      settings: "Settings",
      login: "Sign In",
      reservation_today: "Today Reservation",
      edit_user: "Edit User",
      edit_password: "Edit Password",
      edit_drug: "Edit Drug",
      edit_expense: "Edit Expense",
      edit_lab_test: "Edit Exmination",
      edit_reservation: "Edit Reservation",
      edit_setting: "Edit Setting",
      edit_xray: "Edit X-Ray",
      add_user: "Add User",
      add_drug: "Add Drug",
      add_expense: "Add Expense",
      add_lab_test: "Add Lab Test",
      add_xray: "Add X-Ray",
      add_patient: "Add Patient",
      add_reservation: "Add Reservation",
      my_reservation: "My Reservation",
      add_setting: "Add Setting",
      name: "Name",
      user_name: "User Name",
      patient_name: "Patient name",
      xray_name: "X-Ray Name",
      phone: "Phone",
      "phone#1": "phone number 1",
      "phone#2": "phone number 2",
      gender: "Gender",
      id_number: "id number",
      email: "Email",
      operations: "Operations",
      value: "Value",
      notes: "Notes",
      edit: "Edit",
      id: "ID",
      delete: "Delete",
      yes: "Yes",
      no: "No",
      date: "Date",
      day: "Day",
      start_time: "Start time",
      end_time: "End time",
      price: "Price",
      status: "Status",
      save: "Save",
      male: "male",
      female: "female",
      admin: "admin",
      secertary: "secertary",
      password: "Password",
      birthdate: "Birthdate",
      upload_image: "Upload image",
      pending: "Pending",
      delete_user_sure: "Are you sure you want to delete this account?",
      delete_xray_sure: "Are you sure you want to delete this picture?",
      delete_payment_sure: "Are you sure you want to delete this payment?",
      delete_setting_sure: "Are you sure you want to delete these settings?",
      delete_reservation_sure: "Are you sure you want to delete this reservation?",
      delete_patient_sure: "Are you sure you want to delete this patient?",
      delete_lab_sure: "Are you sure you want to delete this lab?",
      delete_expense_sure: "Are you sure you want to delete this expense?",
      delete_drug_sure: "Are you sure you want to delete this drug?",
      add_drug_success: "This medicine has been added successfully!",
      add_expense_success: "This expense has been added successfully!",
      add_lab_success: "This exmination has been added successfully!",
      add_patient_success: "This patient has been added successfully!",
      add_reservation_success: "This reservation has been added successfully!",
      add_setting_success: "This payment has been added successfully!",
      add_payment_success: "This payment has been added successfully!",
      add_user_success: "This user has been added successfully!",
      add_xray_success: "This xray picture has been added successfully!",
      add_drug_empty: "Please enter the name of the medicine",
      add_expense_empty: "Please enter the name of the expense",
      add_lab_empty: "Please enter the name of the exmination",
      add_patient_empty: "Please fill in all the blanks",
      add_setting_empty: "Please fill in all the blanks",
      add_xray_empty: "Please fill in all the blanks",
      add_payment_empty: "Please fill in all the blanks",
      login_success: "You are logged in successfully",
      login_failed: "The password entered is incorrect",
      login_empty: "Please enter your email and password",
      edit_password_success: "Your password has been modified successfully",
      edit_profile_success: "Your profile has been modified successfully",
      "ok!!": "ok!",
      ok: "ok",
      res: "reservation",
      logout: "Logout",
      res_done: "Visited",
      res_failed: "Not visited",
      res_remove: "cancel visit",
      wel_back: "Welcome Back",
      remember_me: "Remember me",
      back_up: "Back Up",
      choose_file: "Choose File",
      show: "Show",
      add: "Add",
      email_password_login: "Enter your email and password to sign in",
      delete_post_sure: "Delete post sure?",
    },
  },
  ar: {
    translation: {
      janauary: "يناير 1",
      febreuery: "فبراير 2",
      march: "مارس 3",
      april: "أبريل 4",
      may: "مايو 5",
      june: "يونيو 6",
      july: "يوليو 7",
      august: "أغسطس 8",
      september: "أيلول 9 ",
      octuber: "اكتوبر 10",
      november: "تشرين الثاني 11",
      december: "كانون الاول 12",
      logout_success: "تم تسجيل خروجك بنجاح",
      satarday: "السبت",
      sunday: "الأحد",
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wedensday: "الأربعاء",
      thursday: "الخميس",
      friday: "الجمعه",
      user: "مستخدم",
      users: "المستخدمين",
      patients: "المرضى",
      reservations: "الحجوزات",
      drugs: "أدويه",
      lab_tests: "الفحوصات المخبريه",
      expenses: "المصروفات",
      clinics: "العيادات",
      xray: "صور أشعه",
      dashboard: "لوحه التحكم",
      instructions: "تعليمات",
      description: "الوصف",
      settings: "الاعدادات",
      login: "تسجيل دخول",
      reservation_today: "حجوزات اليوم",
      edit_user: "تعديل المستخدم",
      edit_password: "تعديل كلمه المرور",
      edit_drug: "تعديل الدواء",
      edit_expense: "تعديل المصروف",
      edit_lab_test: "تعديل الفحص الطبي",
      edit_reservation: "تعديل حجز",
      edit_xray: "تعديل الصوره",
      edit_setting: "تعديل الاعدادات",
      add_user: "اضافه مستخدم",
      add_drug: "اضافه دواء",
      add_expense: "اضافه مصروفات",
      add_lab_test: "اضافه فحص مخبري",
      add_patient: "اضافه مريض",
      add_xray: "اضافه صوره أشعه",
      add_reservation: "اضافه حجز",
      my_reservation: "حجوزاتي",
      add_setting: "اضافه اعدادات",
      name: "الأسم",
      user_name: "اسم المستخدم",
      patient_name: "اسم المريض",
      xray_name: "اسم الصوره",
      phone: "الهاتف",
      "phone#1": "رقم الهاتف الأول",
      "phone#2": "رقم الهاتف الثاني",
      gender: "الجنس",
      id_number: "رقم الهويه",
      email: "البريد الالكتروني",
      email_capital: "البريد الالكتروني",
      operations: "العمليات",
      value: "القيمه",
      notes: "ملاحظات",
      edit: "تعديل",
      id: "الرقم",
      delete: "حذف",
      yes: "نعم",
      no: "لا",
      date: "التاريخ",
      day: "اليوم",
      start_time: "وقت البدايه",
      end_time: "وثت النهايه",
      price: "السعر",
      status: "الحاله",
      save: "حفظ",
      male: "ذكر",
      female: "انثى",
      admin: "أدمن",
      secertary: "سكرتاريا",
      password: "كلمه المرور",
      birthdate: "تاريخ الميلاد",
      upload_image: "رفع صوره",
      pending: "قيد الانتظار",
      delete_user_sure: "هل تريد بالتأكيد حذف هذا الحساب ؟",
      delete_xray_sure: "هل تريد بالتأكيد حذف هذه الصوره ؟",
      delete_payment_sure: "هل تريد بالتأكيد حذف هذه الدفعه ؟",
      delete_setting_sure: "هل تريد بالتأكيد حذف هذه الاعدادات ؟",
      delete_reservation_sure: "هل تريد بالتأكيد حذف هذا الحجز ؟",
      delete_patient_sure: "هل تريد بالتأكيد حذف هذا المريض ؟",
      delete_lab_sure: "هل تريد بالتأكيد حذف هذا الفحص ؟",
      delete_expense_sure: "هل تريد بالتأكيد حذف هذا المصروف ؟",
      delete_drug_sure: "هل تريد بالتأكيد حذف هذا الدواء ؟",
      add_drug_success: "تم اضافه هذا الدواء بنجاح!",
      add_expense_success: "تم اضافه هذا المصروف بنجاح!",
      add_lab_success: "تم اضافه هذا الفحص بنجاح!",
      add_patient_success: "تم اضافه هذا المريض بنجاح!",
      add_setting_success: "تم اضافه هذه الدفعه بنجاح!",
      add_user_success: "تم اضافه هذا المستخدم بنجاح!",
      add_xray_success: "تم اضافه هذه الصوره بنجاح!",
      add_payment_success: "تم اضافه هذه الدفعه بنجاح!",
      add_drug_empty: "الرجاء ادخال اسم الدواء",
      add_expense_empty: "الرجاء ادخال اسم المصروف",
      add_lab_empty: "الرجاء ادخال اسم الفحص",
      add_patient_empty: "الرجاء املئ جميع الفراغات",
      add_setting_empty: "الرجاء املئ جميع الفراغات",
      add_sray_empty: "الرجاء املئ جميع الفراغات",
      add_payment_empty: "الرجاء املئ جميع الفراغات",
      login_success: "تم تسجيل دخولك بنجاح",
      login_failed: "كلمة المرور التي تم إدخالها غير صحيحة",
      login_empty: "الرجاء ادخال الايميل و كلمه المرور",
      edit_password_success: "تم تعديل كلمة المرور الخاصة بك بنجاح",
      edit_profile_success: "تم تعديل حسابك بنجاح",
      "ok!!": "!حسنا",
      ok: "حسنا",
      res: "حجز",
      logout: "تسجيل خروج",
      res_done: "تمت الزياره",
      res_failed: "لم تتم الزياره",
      res_remove: "الغاء الزياره",
      wel_back: "مرحبا بعودتك",
      remember_me: "تذكرني",
      back_up: "نسخه احتياطيه",
      choose_file: "اختر ملف",
      show: "عرض",
      add: "اضافه",
      email_password_login: "أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول",
      delete_post_sure: "هل تريد حذف المنشور بالتأكيد؟",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
