/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useState } from "react";
import PropTypes from "prop-types";

export default function AddOrderFromExcelDialog({
  phone_number,
  customer_id,
  container_id,
  delivery_date,
  order_number,
  file,
}) {
  AddOrderFromExcelDialog.propTypes = {
    phone_number: PropTypes.string.isRequired,
    customer_id: PropTypes.string.isRequired,
    container_id: PropTypes.string.isRequired,
    delivery_date: PropTypes.string.isRequired,
    order_number: PropTypes.string.isRequired,
    file: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };

  function addOrderTest() {
    const formdata = new FormData();
    formdata.append("delivery_date", delivery_date);
    formdata.append("phone_number", phone_number);
    formdata.append("order_code", order_number);
    formdata.append("customer_id", customer_id);
    formdata.append("container_id", container_id);
    formdata.append("file_order_details", file);
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/uplode_order";
    console.log("file", file);
    axios
      .post(url, formdata, headers)
      .then((response) => {
        // console.log("success");
        // console.log(formData);
        handleCloseLoading();
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="contained"
        style={{ color: "white", marginTop: "20px" }}
        onClick={() => {
          handleClickOpenLoading();
          if (document.getElementById("shop_no1").value === "") {
            handleCloseLoading();
            handleClickOpenEmpty();
          } else {
            addOrderTest();
          }
        }}
      >
        Add Order
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم اضافه الطلبيه بنجاح</DialogTitle>
        <DialogContent>{/**/}</DialogContent>
        <DialogActions>
          {/* navigate("/add_user"); */}
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/orders");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("add_lab_empty")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
