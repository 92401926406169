import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setloading] = useState(false);
  // const [search, setSearch] = useState(false);
  // function changeboolvalue() {
  //   setSearch(false);
  // }

  const fetchData = async () => {
    const customerId = sessionStorage.getItem("customer_id");
    // const headers = { ContentType: `application/json` };

    const response = await fetch(
      sessionStorage.getItem("role_id") === "admin"
        ? "https://trust-cargo.co/anas_backend/api/messages"
        : `https://trust-cargo.co/anas_backend/api/customer_messages/${customerId}`
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setMessages(data["messages"]);
    setloading(false);
  };
  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);

  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "delete_message",
    fetchData
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  return {
    t,
    navigate,
    loading,
    messages,
    fetchData,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  };
};

export default useMessages;
