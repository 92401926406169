import useFormValidation from "hooks/general/useFormValidation";
import useUsers from "hooks/users/useUsers";
import { useState } from "react";
import axios from "axios";

const useCustomerAdding = () => {
  const { users } = useUsers();
  const [name, setName] = useState();
  const [code, setCode] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [vatName, setVatname] = useState();
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  function AddCustomer() {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("code", code);
    formData.append("phone_number", phone);
    formData.append("address", address);
    formData.append("vat_name", vatName);
    formData.append("usesr_id", user);
    const headers = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    axios
      .post("https://trust-cargo.co/anas_backend/api/add_customer", formData, { headers })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
        setLoading(false);
      });
  }

  const { handleSubmit, validated } = useFormValidation(AddCustomer);

  return {
    name,
    setName,
    phone,
    setPhone,
    code,
    setCode,
    address,
    setAddress,
    vat_name: vatName,
    setVatname,
    user,
    setUser,
    loading,
    setLoading,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
    handleSubmit,
    validated,
    users,
  };
};

export default useCustomerAdding;
