/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
/* eslint no-underscore-dangle: 0 */

import axios from "axios";
import useContainers from "hooks/containers/useContainers";
import useCustomers from "hooks/customers/useCustomers";
import useFormValidation from "hooks/general/useFormValidation";
import { useEffect, useState } from "react";

const useAllOrdersDetails = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [markedItems, setMarkedItems] = useState([]);
  const { customers } = useCustomers();
  const { containers } = useContainers();
  const [totalCTN, setTotalCTN] = useState(0);
  const [totalGW, setTotalGW] = useState(0);
  const [totalTCBM, setTotalTCBM] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedContainerID, setSelectedContainerID] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [customerFilter, setCustomerFilter] = useState("");
  const [containerFilter, setContainerFilter] = useState("");
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const toggleMarkedItem = (id) => {
    const newMarkedItems = markedItems.includes(id)
      ? markedItems.filter((itemId) => itemId !== id)
      : [...markedItems, id];
    setMarkedItems(newMarkedItems);
    const newSelectedOrders = orders.filter((item) => newMarkedItems.includes(item.id));
    setSelectedOrders(newSelectedOrders);
  };

  const markAll = () => {
    if (markedItems.length === orders.length) {
      setMarkedItems([]);
      setSelectedOrders([]);
    } else {
      const newItems = filteredOrders.map((item) => item.id);
      setMarkedItems(newItems);
      setSelectedOrders(filteredOrders);
    }
  };

  const calculateTotals = () => {
    console.log("entered calculation");
    const toCTN = selectedOrders.reduce((total, e) => {
      return total + Number(e.ctn);
    }, 0);
    const toGW = selectedOrders.reduce((total, e) => {
      return total + Number(e.g_w);
    }, 0);
    const toTCBM = selectedOrders.reduce((total, e) => {
      return total + Number(e.t_cbm);
    }, 0);
    const toAmount = selectedOrders.reduce((total, e) => {
      return total + Number(e.amount);
    }, 0);

    console.log(toCTN, toTCBM, toAmount, toGW);

    setTotalCTN(toCTN.toFixed(2));
    setTotalTCBM(toTCBM.toFixed(2));
    setTotalAmount(toAmount.toFixed(2));
    setTotalGW(toGW.toFixed(2));
  };

  useEffect(() => {
    calculateTotals();
  }, [selectedOrders]);

  const handleFieldChange = (index, field, value) => {
    const updatedOrders = [...filteredOrders];
    updatedOrders[index] = { ...updatedOrders[index], [field]: value };
    switch (field) {
      case "ctn":
        updatedOrders[index] = {
          ...updatedOrders[index],
          t_qty: updatedOrders[index].ctn * updatedOrders[index].qty_ctn,
          t_cbm: updatedOrders[index].ctn * updatedOrders[index].ctn,
          g_w: updatedOrders[index].n_w * updatedOrders[index].ctn,
        };
        break;
      case "qty_ctn":
        updatedOrders[index] = {
          ...updatedOrders[index],
          t_qty: updatedOrders[index].ctn * updatedOrders[index].qty_ctn,
        };
        break;
      case "cbm":
        updatedOrders[index] = {
          ...updatedOrders[index],
          t_cbm: updatedOrders[index].ctn * updatedOrders[index].cbm,
        };
        break;
      case "n_w":
        updatedOrders[index] = {
          ...updatedOrders[index],
          g_w: updatedOrders[index].n_w * updatedOrders[index].ctn,
        };
        break;
      default:
        break;
    }
    const updatedSelected = updatedOrders.filter((item) => markedItems.includes(item.id));
    setSelectedOrders(updatedSelected);
    setOrders(updatedOrders);
    console.log(selectedOrders);
  };

  async function getOrderDetails() {
    const headers = { ContentType: `application/json` };
    const response = await fetch(`https://trust-cargo.co/anas_backend/api/order_details`, {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["order_details"]);
    const newItems = data["order_details"].map((item) => item.id);
    setMarkedItems(newItems);
    setSelectedOrders(data["order_details"]);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getOrderDetails();
  }, []);

  const handleChangeContainerID = (val) => {
    setSelectedContainerID(val);
  };
  const filteredOrders = orders.filter((item) => {
    const matchesStatus =
      statusFilter === "" || statusFilter === "all" || item.order_status === statusFilter;
    const matchesCustomer =
      customerFilter === "" || customerFilter === "all" || item.customer_id === customerFilter;
    const matchesContainer =
      containerFilter === "" || containerFilter === "all" || item.container_id === containerFilter;

    return matchesStatus && matchesCustomer && matchesContainer;
  });

  useEffect(() => {
    setMarkedItems([]);
    setSelectedOrders([]);
    const newItems = filteredOrders.map((item) => item.id);
    setMarkedItems(newItems);
    setSelectedOrders(filteredOrders);
    console.log(selectedOrders, filteredOrders)
  }, [customerFilter, statusFilter, containerFilter]);

  function EditOrder() {
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/confirm_order";
    const formData = new FormData();
    formData.append(`container_id`, selectedContainerID);
    selectedOrders.forEach((order, index) => {
      formData.append(`customer_id[${index}]`, order.customer_id);
      formData.append(`our_item[${index}]`, order.our_item);
      formData.append(`item_number[${index}]`, order.item_number);
      formData.append(`id[${index}]`, order.id);
      formData.append(`order_code[${index}]`, order.order_code);
      formData.append(`order_id[${index}]`, order.order_id);
      formData.append(`ctn[${index}]`, order.ctn);
      formData.append(`amount[${index}]`, order.amount);
      formData.append(`cbm[${index}]`, order.cbm);
      formData.append(`status[${index}]`, order.status);
      formData.append(`notes[${index}]`, order.notes);
      formData.append(`qty_ctn[${index}]`, order.qty_ctn);
      formData.append(`t_qty[${index}]`, order.t_qty);
      formData.append(`u_price[${index}]`, order.u_price);
      formData.append(`t_cbm[${index}]`, order.t_cbm);
      formData.append(`n_w[${index}]`, order.n_w);
      formData.append(`gw[${index}]`, order.gw);
    });
    axios
      .post(url, formData, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { validated, handleSubmit } = useFormValidation(EditOrder);

  return {
    validated,
    handleSubmit,
    handleChangeContainerID,
    filteredOrders,
    handleFieldChange,
    markAll,
    toggleMarkedItem,
    dialogOpen,
    setDialogOpen,
    loading,
    selectedOrders,
    markedItems,
    customers,
    containers,
    totalCTN,
    totalGW,
    totalTCBM,
    totalAmount,
    statusFilter,
    setStatusFilter,
    customerFilter,
    setCustomerFilter,
    containerFilter,
    setContainerFilter,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
  };
};

export default useAllOrdersDetails;
