/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
// import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactLoading from "react-loading";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import usePostAdding from "hooks/posts/usePostAdding";
import { CCol, CForm, CFormInput, CRow } from "@coreui/react";
import { Spinner } from "react-bootstrap";

function AddPost() {
  const {
    files1,
    setFiles1,
    body,
    setBody,
    files,
    setFiles,
    handleChange,
    handleSubmit,
    validated,
    loading,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
  } = usePostAdding();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="body"
                    feedbackInvalid="body is required"
                    id="categorybodyAr"
                    label="body"
                    required
                    onChange={(e) => {
                      setBody(e.target.value);
                    }}
                    value={body}
                  />
                </CCol>
              </CRow>
              <div>
                <CFormInput
                  label="Add Image"
                  type="file"
                  id="categoryPic"
                  feedbackInvalid="Image required"
                  required
                  onChange={handleChange}
                />
                <div
                  className="form-group d-flex flex-row  col-md-12 d-grid gap-2"
                  style={{ marginTop: "20px" }}
                >
                  {files.map((item) => (
                    <img src={item} style={{ width: "300px" }} />
                  ))}
                </div>
              </div>
              {loading ? (
                <ReactLoading color="blue" height={50} width={50} type="spokes" />
              ) : (
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white", width: "15%" }}
                  type="submit"
                >
                  <span style={{ fontWeight: "bolder" }}>Add Post</span>
                </Button>
              )}
            </CForm>
            <Dialog
              open={dialogSuccess}
              onClose={() => {
                setDialogSuccess(false);
                navigate("/posts");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Post Added Successfully</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    navigate("/posts");
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogError}
              onClose={() => {
                setDialogError(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Adding post failed</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogError(false);
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <div style={{ width: "400px", margin: "20px" }}>
              {/* <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  body
                </label>
                <textarea
                  id="freeform"
                  name="freeform"
                  rows="4"
                  cols="50"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                >
                  Enter your description here
                </textarea>
              </div>
              <img src={file} />
              <div className="form-group  col-md-12">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Add Image
                </label>
                <input
                  required
                  id="name"
                  type="file"
                  multiple
                  className="form-control"
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div
                className="form-group d-flex flex-row  col-md-12 d-grid gap-2"
                style={{ marginTop: "20px" }}
              >
                {files.map((item) => (
                  <img src={item} style={{ width: "300px" }} />
                ))}
              </div> */}
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddPost;
