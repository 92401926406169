/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
// Soft UI Dashboard React components
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import useUsers from "hooks/users/useUsers";
import DeleteDialog from "components/deleteDialog";

function Users() {
  const { users, loading, navigate, t, dialogdelete, openDialog, closeDialog, executeDelete } =
    useUsers();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* <SuiBox py={0}> */}
      {/* <SuiBox mb={0}> */}
      <Card>
        {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SuiTypography variant="h6">Users</SuiTypography>
        </SuiBox> */}
        <div style={{ width: "200px", marginLeft: "20px", marginTop: "20px", color: "green" }}>
          <Button
            variant="contained"
            style={{ color: "white" }}
            onClick={() => {
              navigate("/add_user");
            }}
          >
            {t("add_user")}
          </Button>
        </div>
        {loading ? (
          <div
            style={{
              width: "1000",
              height: "50px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <progress className="pure-material-progress-circular" />
          </div>
        ) : (
          <Table sx={{ minWidth: 1000 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ display: "flex" }}>
                <Box>
                  <TableCell sx={{ width: window.innerWidth / 6 }} align="start">
                    Name
                  </TableCell>
                </Box>
                <Box>
                  <TableCell sx={{ width: window.innerWidth / 6 }} align="start">
                    Email
                  </TableCell>
                </Box>
                <Box>
                  <TableCell sx={{ width: window.innerWidth / 6 }} align="start">
                    Mobile
                  </TableCell>
                </Box>
                <Box>
                  <TableCell sx={{ width: window.innerWidth / 6 }} align="start">
                    Role ID
                  </TableCell>
                </Box>
                <Box>
                  <TableCell sx={{ width: window.innerWidth / 6 }} align="start">
                    {t("operations")}
                  </TableCell>
                </Box>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  style={{ marginLeft: "20px" }}
                  sx={{
                    // "&:last-child td, &:last-child th": { border: 0 },
                    display: "flex",
                  }}
                >
                  <Box>
                    <TableCell
                      sx={{
                        width: window.innerWidth / 6,
                        // borderRight: "1px solid #e6e9ec",
                        overflowWrap: "break-word",
                      }}
                      align="start"
                    >
                      {user.name ?? "-"}
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell
                      sx={{
                        width: window.innerWidth / 6,

                        // borderRight: "1px solid #e6e9ec",
                        overflowWrap: "break-word",
                      }}
                      align="start"
                    >
                      {user.email ?? "-"}
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell
                      sx={{
                        width: window.innerWidth / 6,
                        // borderRight: "1px solid #e6e9ec",
                        overflowWrap: "break-word",
                      }}
                      align="start"
                    >
                      {user.mobile ?? "-"}
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell
                      align="start"
                      sx={{
                        width: window.innerWidth / 6,
                        // borderRight: "1px solid #e6e9ec",
                        overflowWrap: "break-word",
                      }}
                    >
                      {user.role_id ?? "-"}
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell
                      sx={{
                        width: window.innerWidth / 6,

                        // borderRight: "1px solid #e6e9ec",
                        overflowWrap: "break-word",
                      }}
                      align="start"
                      style={{ flexDirection: "row" }}
                    >
                      <SuiTypography
                        component="a"
                        href="#"
                        variant="caption"
                        color="secondary"
                        fontWeight="medium"
                        onClick={() => {
                          navigate(`/edit_user/${user.id}`);
                        }}
                      >
                        {t("edit")}
                      </SuiTypography>
                      <SuiTypography
                        component="a"
                        variant="caption"
                        color="primary"
                        fontWeight="medium"
                        margin="15px"
                        onClick={() => {
                          openDialog(user.id);
                        }}
                      >
                        {t("delete")}
                      </SuiTypography>{" "}
                    </TableCell>
                  </Box>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <DeleteDialog
          dialogdelete={dialogdelete}
          closeDialog={closeDialog}
          deleteItem={executeDelete}
        />
      </Card>
      {/* </SuiBox> */}
      {/* </SuiBox> */}
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
