/* eslint-disable prefer-const */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ConfirmOrder from "./confirm_order";
import ExportData from "./export_order";
// import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";
const ExcelJS = require("exceljs");

const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
  return promise;
};
function AllOrdersCustomers() {
  // const  rows: [1];
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  const [customer, setCustomer] = useState("");
  const [loading, setloading] = useState(false);
  const [rows, setRows] = useState([0]);
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [containers, setContainers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [containerStatus, setContainerStatus] = useState("");

  const getContainers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setContainers(data["containers"]);
  };
  const getCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCustomers(data["customers"]);
  };
  const getUsersCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers", { headers });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["customers"]);
    setCustomer(data["customers"][0]["code"]);
  };

  const [shopnumber1, setShopNumber1] = useState("");
  const [shopnumber2, setShopNumber2] = useState("");
  const [phone, setPhone] = useState("");

  const [order_number, setOrderNumber] = useState("");
  const [deliverydate, setDeliveryDate] = useState("");

  // contants of my array
  const [ordercode, setOrderCode] = useState([""]);
  const [container_id, setContainerID] = useState([""]);
  const [customer_id, setCustomerID] = useState([""]);
  const [itemarray, setItemArray] = useState([""]);
  const [itemnumber, setItemNumber] = useState([""]);
  const [photo, setPhoto] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([]);
  const [qtytoctn, setQtyToCtn] = useState([""]);
  const [selectedContainer, setSelectedContainer] = useState("");

  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  // const [amount, setAmount] = useState([""]);
  // eslint-disable-next-line arrow-body-style

  // const total_cbm = ttocbm.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_ctn = ctn.reduce((e1, e2) => {
  //   return e1 + e2;
  // });

  const ttoqty = ctn.map((e, index) => {
    return e * qtytoctn[index];
  });
  const amount = utoprice.map((e, index) => {
    return e * ttoqty[index];
  });

  // const total_amount = amount.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_items = itemarray.reduce((e1, e2) => {
  //   return e1 + e2;
  // });

  // const [ttocbm, setTtoCBM] = useState([""]);
  const [nw, setNW] = useState([""]);
  // const [gw, setGW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);

  // const total_gw = gw.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  async function getOrderDetails() {
    const customer_code = sessionStorage.getItem("customer_code");
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/customer_order_details/${customer_code}`,
      { headers }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["orders_details"]);
    setloading(false);
  }
  const filteredOrder = orders.filter((order) => {
    if (!selectedContainer || selectedContainer === "all") {
      return true;
    }
    return order.container_id === selectedContainer;
  });
  const visibleCtn = [];
  for (let i = 0; i < filteredOrder.length; i += 1) {
    // if (filteredOrder) {
    visibleCtn.push(ctn[i]);
    // }
  }
  const ttocbm = cbm.map((e, index) => {
    return e * visibleCtn[index] || 0;
  });
  const gw = filteredOrder.map((e, index) => {
    const nw1 = Number(e.n_w);
    const ctnValue = Number(ctn[index]);
    const gwValue = nw1 * ctnValue;

    // console.log(`Index ${index}: nw = ${nw}, ctn = ${ctnValue}, gw = ${gwValue}`);

    return gwValue;
  });

  const handleOrderCodeChange = (event, index) => {
    setOrderCode((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleContainerIDChange = (event, index) => {
    setContainerID((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCustomerIDChange = (event, index) => {
    setCustomerID((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleItemNumberChange = (event, index) => {
    setItemNumber((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handlePhotoChange = (event, index) => {
    setPhoto((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDescChange = (event, index) => {
    setDescription1((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDesc1Change = (event, index) => {
    setDescription1((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCategoryChange = (event, index) => {
    setCategory((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCTNChange = (event, index) => {
    setCTN((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOQTYChange = (event, index) => {
  //   setTtoQty((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };

  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleAmountChange = (event, index) => {
  //   setAmount((state) =>
  //     state.map((el, index1) => {
  //       console.log("enver", event.target.value);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOCBMChange = (event, index) => {
  //   setTtoCBM((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleGWChange = (event, index) => {
  //   setGW((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        // console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const total_ctn =
    visibleCtn.length !== 0
      ? visibleCtn.reduce((total, order) => {
          // console.log("amount");
          // console.log(amount);
          return total + Number(order);
        }, 0)
      : "0";
  const total_gw =
    filteredOrder.length !== 0
      ? gw.reduce((total, gwValue) => {
          return total + Number(gwValue);
        }, 0)
      : 0;

  const total_t_cbm =
    filteredOrder.length !== 0
      ? ttocbm.reduce((total, ttocbmValue) => {
          return total + Number(ttocbmValue);
        }, 0)
      : 0;
  const total_amount =
    filteredOrder.length !== 0
      ? amount.reduce((total, ttocbmValue) => {
          return total + Number(ttocbmValue);
        }, 0)
      : 0;

  useEffect(() => {
    setloading(true);
    getOrderDetails();
    getUsersCustomers();
    getContainers();
    getCustomers();
  }, []);
  useEffect(() => {
    const ordercode1 = [];
    orders.forEach((e) => {
      ordercode1.push(e.order_code);
    });
    const container_id1 = [];
    orders.forEach((e) => {
      container_id1.push(e.container_name);
    });
    const customer_id1 = [];
    orders.forEach((e) => {
      customer_id1.push(e.customer_id);
    });
    const ite1 = [];
    orders.forEach((e) => {
      ite1.push(e.our_item);
    });
    const photo1 = [];
    orders.forEach((e) => {
      photo1.push(e.photo);
    });
    const cate = [];
    orders.forEach((e) => {
      cate.push(e.category);
    });
    const ctnorders = [];
    orders.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    const qtytoctnorders = [];
    orders.forEach((e) => {
      qtytoctnorders.push(e.qty_ctn);
    });

    const desc1 = [];
    orders.forEach((e) => {
      desc1.push(e.description1);
    });
    const desc2 = [];
    orders.forEach((e) => {
      desc2.push(e.description2);
    });
    const utopriceorders = [];
    orders.forEach((e) => {
      utopriceorders.push(e.u_price);
    });
    const cbmorders = [];
    orders.forEach((e) => {
      cbmorders.push(e.cbm);
    });

    const nworders = [];
    orders.forEach((e) => {
      nworders.push(e.n_w);
    });

    const statusorders = [];
    orders.forEach((e) => {
      statusorders.push(e.status);
    });
    const notesorders = [];
    orders.forEach((e) => {
      notesorders.push(e.notes);
    });
    setOrderCode(ordercode1);
    setContainerID(container_id1);
    setCustomerID(customer_id1);
    setItemArray(ite1);
    setPhoto(photo1);
    setCategory(cate);
    setCTN(ctnorders);
    setQtyToCtn(qtytoctnorders);
    setDescription1(desc1);
    setDescription2(desc2);
    setUtoPrice(utopriceorders);
    setCBM(cbmorders);
    setNW(nworders);
    setStatus(statusorders);
    setNotes(notesorders);
  }, [orders]);
  const customerID = sessionStorage.getItem("customer_code");
  // async function changeContainer(ID) {
  //   const headers = { ContentType: `application/json` };
  //   const response = await fetch(
  //     `https://trust-cargo.co/anas_backend/api/filter_order_details_container_id/${customerID}/${ID}`,
  //     {
  //       headers,
  //     }
  //   );
  //   const data = await response.json();
  //   // eslint-disable-next-line dot-notation
  //   setOrders(data["order_details"]);
  //   console.log("id", ID);
  // }
  // console.log("customerID", customerID);

  async function changeCustomer(ID) {
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/filter_order_details_customer_id/${ID}`,
      {
        headers,
      }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["order_details"]);
  }

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedContainer(selectedValue);
    console.log(selectedValue, "selected value");
    console.log(containers, "containers");
    const selectContainer = containers.find((item) => item.id === Number(selectedValue));
    console.log(selectContainer, "selected container");

    // setContainerStatus(containers.filter((item) => item.id === selectedValue).status);
    if (selectContainer) {
      setContainerStatus(selectContainer.status);
    }
    console.log(containerStatus, "status");
  };

  const handleChangeCustomer = (event) => {
    changeCustomer(event.target.value);
  };
  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.columns = [
      { header: "Order Code", key: "order_code", width: 10 },
      { header: "Customer Code", key: "customer_code", width: 10 },
      { header: "Container ID", key: "container_id", width: 10 },
      { header: "Our Item", key: "our_item", width: 20 },
      { header: "Item Number", key: "item_number", width: 20 },
      { header: "Status", key: "status", width: 20 },
      { header: "Description English", key: "description2", width: 20 },
      { header: "ctn", key: "ctn", width: 20 },
      { header: "qty_ctn", key: "qty_ctn", width: 20 },
      { header: "t_qty", key: "t_qty", width: 10 },
      { header: "cbm", key: "cbm", width: 10 },
      { header: "t_cbm", key: "t_cbm", width: 10 },
      { header: "n_w", key: "n_w", width: 10 },
      { header: "g_w", key: "g_w", width: 10 },
      { header: "U price", key: "u_price", width: 10 },
      { header: "Amount", key: "amount", width: 10 },
      { header: "notes", key: "notes", width: 30 },
      { header: "photo", key: "photo", width: 20 },
    ];
    const promise = Promise.all(
      orders?.map(async (product, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          order_code: product?.order_code,
          container_id: product?.container_id,
          customer_id: product?.customer_id,
          our_item: product?.our_item,
          item_number: product?.item_number,
          status: product?.status,
          description2: product?.description2,
          ctn: product?.ctn,
          qty_ctn: product?.qty_ctn,
          t_qty: product?.t_qty,
          cbm: product?.cbm,
          t_cbm: product?.t_cbm,
          n_w: product?.n_w,
          g_w: product?.g_w,
          u_price: product?.u_price,
          amount: product?.amount,

          notes: product?.notes,
        });
        const result = await toDataURL(product?.photo);
        const splitted = product?.photo.split(".");
        const extName = splitted[splitted.length - 1];
        let imageID2;
        if (product?.photo === "https://trust-cargo.co/anas_backend/storage/image/") {
          imageID2 = workbook.addImage({
            base64: close,
            extension: "jpg",
          });
        } else {
          imageID2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });
        }
        // const imageId2 = workbook.addImage({
        //   base64: result.base64Url,
        //   extension: extName,
        // });

        sheet.addImage(imageID2, {
          tl: { col: 17, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );
    promise.then(() => {
      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "sheet.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };
  const customerName = sessionStorage.getItem("customer_name");
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">{customerName}</SuiTypography>
          </SuiBox>

          <div className="row" style={{ margin: "10px" }}>
            <div className="form-group  col-md-2">
              <label
                className="control-label"
                htmlFor="name"
                style={{ fontSize: "15px", marginTop: "20px" }}
              >
                Filter Containers
              </label>
              <select
                id="shop"
                className="form-control"
                style={{ height: "40px" }}
                onChange={handleChange}
              >
                <option value="all">All</option>
                {containers.map((user) => (
                  <>
                    <option value={user.id}>{user.name}</option>
                    {/* <option value={user.id}>{user.status}</option> */}
                  </>
                ))}
              </select>
            </div>
            <div className="form-group  col-md-4" style={{ fontSize: "17px", marginTop: "45px" }}>
              <span>Container Status: </span>
              {containerStatus}
            </div>
            <div className="col-md-3">
              <Button
                variant="contained"
                style={{ color: "white", marginTop: "45px" }}
                onClick={() => exportExcelFile()}
              >
                Export To Excel
              </Button>{" "}
            </div>
          </div>
          <div className="row" style={{ margin: "10px" }}>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Total G.w
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={Number(total_gw).toFixed(1)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Total T_CBM
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={Number(total_t_cbm).toFixed(1)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Total Amount
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={Number(total_amount).toFixed(1)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Total CTN
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  placeholder="Total CTN"
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={Number(total_ctn).toFixed(1)}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div
              style={{
                width: "1000",
                height: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <progress className="pure-material-progress-circular" />
            </div>
          ) : (
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    {[
                      { label: "O. Code", width: "100px", align: "start" },
                      { label: "Our Item", width: "100px", align: "center" },
                      { label: "Photo", width: "100px", align: "center" },
                      { label: "Desc En.", width: "200px", align: "center" },
                      { label: "CTN", width: "70px", align: "center" },
                      { label: "QTY/CTN", width: "50px", align: "center" },
                      { label: "T/QTY", width: "50px", align: "center" },
                      { label: "Uprice", width: "50px", align: "center" },
                      { label: "CBM", width: "60px", align: "center" },
                      { label: "T/CBM", width: "70px", align: "center" },
                      { label: "N.W", width: "70px", align: "center" },
                      { label: "G.W", width: "70px", align: "center" },
                      { label: "Status", width: "100px", align: "center" },
                      { label: "O.Status", width: "100px", align: "center" },
                      { label: "Notes", width: "180px", align: "start" },
                    ].map((header, index) => (
                      <Box key={index}>
                        <TableCell
                          sx={{ width: header.width, minWidth: header.width }}
                          align={header.align}
                        >
                          {header.label}
                        </TableCell>
                      </Box>
                    ))}
                  </TableRow>
                </TableHead>
                {filteredOrder.length > 0 ? (
                  <TableBody style={{ display: "block " }}>
                    {filteredOrder.map((vla, index) => (
                      <TableRow key={`${index}key`} sx={{ minWidth: "100px" }}>
                        <Box style={{ overflowX: "auto" }}>
                          <TableCell sx={{ width: "100px", padding: "2px" }} align="start">
                            <input
                              id="item"
                              required
                              onChange={(e) => handleOrderCodeChange(e, index)}
                              value={ordercode[index]}
                              type="text"
                              style={{ width: "90px", minWidth: "90px" }}
                              className="form-control"
                              name="name"
                              placeholder="Order Code"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "100px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              id="item"
                              required
                              onChange={(e) => handleItemChange(e, index)}
                              value={itemarray[index]}
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Our Item"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "100px", padding: "2px" }} align="center">
                            {" "}
                            <img
                              src={photo[index]}
                              style={{ width: "100px", height: "60px" }}
                              alt=""
                            />
                          </TableCell>
                          <TableCell sx={{ width: "200px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              id="desc2"
                              type="text"
                              style={{ width: "200px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Desc English"
                              onChange={(e) => handleDesc2Change(e, index)}
                              value={description2[index]}
                            />
                          </TableCell>
                          <TableCell sx={{ width: "70px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              placeholder="CTN"
                              onChange={(e) => handleCTNChange(e, index)}
                              value={ctn[index]}
                              id="ctn"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "50px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "50px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              placeholder="QTY/CTN"
                              onChange={(e) => handleQTYTOCTNChange(e, index)}
                              value={qtytoctn[index]}
                              id="qtytoctn"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "50px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "50px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              readOnly
                              placeholder="T/QTY"
                              // onChange={(e) => handleTTOQTYChange(e, index)}
                              value={Number(ttoqty[index]).toFixed(1)}
                              id="ttoqty"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "50px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "50px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              placeholder="U-Price"
                              onChange={(e) => handleUTOPriceChange(e, index)}
                              value={Number(utoprice[index]).toFixed(1)}
                              id="cbm"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "60px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              placeholder="CBM"
                              onChange={(e) => handleCBMChange(e, index)}
                              value={Number(cbm[index]).toFixed(1)}
                              id="cbm"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "70px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px", marginLeft: "5px" }}
                              className="form-control"
                              name="name"
                              placeholder="T/CBM"
                              readOnly
                              // onChange={(e) => handleTTOCBMChange(e, index)}
                              value={Number(ttocbm[index]).toFixed(4)}
                              id="ttocbm"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "70px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="N.W"
                              onChange={(e) => handleNWChange(e, index)}
                              value={nw[index]}
                              id="nw"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "70px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              readOnly
                              // onChange={(e) => handleGWChange(e, index)}
                              value={gw[index]}
                              id="gw"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "100px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Status"
                              onChange={(e) => handleStatusChange(e, index)}
                              value={status[index]}
                              id="status"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "100px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder=" Order Status"
                              onChange={(e) => handleStatusChange(e, index)}
                              value={status[index]}
                              id="status"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "180px", padding: "2px" }} align="center">
                            {" "}
                            <input
                              required
                              type="text"
                              style={{ width: "180px", minWidth: "180px" }}
                              className="form-control"
                              name="name"
                              placeholder="Notes"
                              onChange={(e) => handleNotesChange(e, index)}
                              value={notes[index]}
                              id="status"
                            />
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            </SuiBox>
          )}
        </Card>
      </SuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AllOrdersCustomers;
