/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState } from "react";
import axios from 'axios'

const useOrderEditing = (orderId) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  const getSpeceficOrder = async () => {
    setLoading(true);
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_order/${orderId}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setRows(data["order"]["products"]);
    setLoading(false);
  };

  useEffect(() => {
    getSpeceficOrder();
  }, []);

  const handleFieldChange = (index, field, value) => {
    const updatedRow = [...rows];
    updatedRow[index][field] = value;
    switch (field) {
      case "ctn":
        updatedRow[index] = {
          ...updatedRow[index],
          t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
          t_cbm: updatedRow[index].ctn * updatedRow[index].ctn,
          g_w: updatedRow[index].n_w * updatedRow[index].ctn,
        };
        break;
      case "qty_ctn":
        updatedRow[index] = {
          ...updatedRow[index],
          t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
        };
        break;
      case "cbm":
        updatedRow[index] = {
          ...updatedRow[index],
          t_cbm: updatedRow[index].ctn * updatedRow[index].cbm,
        };
        break;
      case "n_w":
        updatedRow[index] = {
          ...updatedRow[index],
          g_w: updatedRow[index].n_w * updatedRow[index].ctn,
        };
        break;
      default:
        break;
    }
    setRows(updatedRow);
  };

  function editOrder() {
    const formData = new FormData();
    formData.append("order_id", orderId);
    rows.forEach((row, index) => {
      formData.append(`our_item[${index}]`, row.our_item);
      formData.append(`item_number[${index}]`, row.item_number);
      formData.append(`description1[${index}]`, row.description2);
      formData.append(`description2[${index}]`, row.description2);
      formData.append(`category[${index}]`, "category");
      formData.append(`order_code[${index}]`, row.order_code);
      formData.append(`ctn[${index}]`, row.ctn);
      formData.append(`amount[${index}]`, row.amount);
      formData.append(`cbm[${index}]`, row.cbm);
      formData.append(`status[${index}]`, row.status);
      formData.append(`notes[${index}]`, row.notes);
      formData.append(`qty_ctn[${index}]`, row.qty_ctn);
      formData.append(`t_qty[${index}]`, row.t_qty);
      formData.append(`u_price[${index}]`, row.u_price);
      formData.append(`t_cbm[${index}]`, row.t_cbm);
      formData.append(`n_w[${index}]`, row.n_w);
      formData.append(`g_w[${index}]`, row.g_w);
      formData.append(`photo[${index}]`, row.photo);
      formData.append(`container_id[${index}]`, row.container_id);
    });

    const headers = { "Content-Type": "application/json" };
    const url = "https://trust-cargo.co/anas_backend/api/edit_order";

    axios
      .post(url, formData, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  return {
    handleFieldChange,
    rows,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    editOrder,
  };
};

export default useOrderEditing;
