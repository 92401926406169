import useFormValidation from "hooks/general/useFormValidation";
import { useState } from "react";
import axios from "axios";

const useUserAdding = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [roleId, setRoleID] = useState("admin");
  const [loading, setLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState();
  const [dialogError, setDialogError] = useState();

  function addUser() {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("role_id", roleId);
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    axios
      .post("https://trust-cargo.co/anas_backend/api/register", formData, { headers })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(addUser);

  return {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    mobile,
    setMobile,
    role_id: roleId,
    setRoleID,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    handleSubmit,
    validated,
  };
};

export default useUserAdding;
