/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable dot-notation */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { Spinner } from "react-bootstrap";

export default function LoginButton() {
  const [loading, setloading] = useState(false);
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogfailed, setOpen3] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };
  const handleOpenfailed = () => {
    setOpen3(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleClosefailed = () => {
    setOpen3(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  const navigate = useNavigate();
  function login() {
    if (
      document.getElementById("email").value === "" ||
      document.getElementById("password").value === ""
    ) {
      handleClickOpenEmpty();
      setloading(false);
    } else {
      const token = sessionStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
      axios
        .post(
          "https://trust-cargo.co/anas_backend/api/login",
          {
            email: document.getElementById("email").value,
            password: document.getElementById("password").value,
          },
          { headers }
        )
        .then((response) => {
          console.log(response);
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("role_id", response.data["user"]["role_id"]);
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_id", response.data["user"]["customer"]["id"])
            : null;
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_code", response.data["user"]["customer"]["code"])
            : null;
          response.data["user"]["role_id"] === "customer"
            ? sessionStorage.setItem("customer_name", response.data["user"]["customer"]["name"])
            : null;
          handleClickOpenSuccess();
          setloading(false);
          sessionStorage.getItem("role_id") === "admin"
            ? navigate("/users")
            : navigate("/messages");
        })
        .catch((error) => {
          handleOpenfailed();
          setloading(false);
        });
    }
  }

  return (
    <div>
      {loading ? (
        <Spinner style={{ marginBottom: 27 }} animation="border" variant="danger" />
      ) : (
        <SuiBox mt={4} mb={1}>
          <SuiButton
            variant="gradient"
            color="info"
            onClick={() => {
              setloading(true);
              login();
            }}
            fullWidth
          >
            {t("login")}
          </SuiButton>
        </SuiBox>
      )}

      {/* <Button onClick={() => this.toggleLoader()} variant="primary" size="lg">
        {loading ? "Hide Loader" : "Show Loader"}
      </Button> */}

      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم تسجيل الدخول بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sessionStorage.getItem("role_id") === "admin"
                ? navigate("/users")
                : navigate("/messages");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">الرجاء تعبئه جميع البيانات</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogfailed}
        onClose={handleClosefailed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          فشلت عمليه تسجيل الدخول , الرجاء المحاوله مره اخرى
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleClosefailed} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
