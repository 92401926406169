/* eslint-disable no-dupe-keys */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

export default function EditOrderData({
  order_code1,
  phone1,
  delivery_date1,
  customer_id1,
  container_id1,
  status1,
}) {
  EditOrderData.propTypes = {
    order_code1: PropTypes.string.isRequired,
    phone1: PropTypes.string.isRequired,
    delivery_date1: PropTypes.string.isRequired,
    customer_id1: PropTypes.string.isRequired,
    container_id1: PropTypes.string.isRequired,
    status1: PropTypes.string.isRequired,
  };
  const route = useLocation().pathname.split("/").slice(1);
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  function EditOrder() {
    const formdata = new FormData();
    formdata.append("id", route[route.length - 1]);
    formdata.append("order_code", order_code1);
    formdata.append("phone_number", phone1);
    formdata.append("delivery_date", delivery_date1);
    formdata.append("customer_id", customer_id1);
    formdata.append("container_id", container_id1);
    formdata.append("status", status1);
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/edit_order_main_data";

    axios
      .post(url, formdata, headers)
      .then((response) => {
        // console.log("success");
        // console.log(formData);
        handleCloseLoading();
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px", color: "white" }}
        onClick={() => {
          handleClickOpenLoading();
          if (order_code1 === "") {
            handleCloseLoading();
            handleClickOpenEmpty();
          } else {
            EditOrder();
          }
        }}
      >
        Edit Order
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order edited successfully</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* navigate("/add_user"); */}
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/orders");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("add_setting_empty")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
