/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import axios from "axios";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/users/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import EditStatusSuccess from "./edit_dialog";

function EditStatus() {
  const route = useLocation().pathname.split("/").slice(1);
  const [name, setName] = useState([]);
  const fetchUsers = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_status/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["status"]["name"]);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);
  const { columns: prCols, rows: prRows } = projectsTableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Status</SuiTypography>
            </SuiBox>
            <div style={{ width: "200px", marginLeft: "20px", color: "green" }}>
              <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <EditStatusSuccess name1={name} />
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditStatus;
