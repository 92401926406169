/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch("https://app.medical-clinic.tk/api/transactions", { headers });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["data"]);
    console.log(users);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Card className="h-100">
      <SuiBox pt={3} px={3}>
        <SuiTypography variant="h6" fontWeight="medium">
          المصروفات
        </SuiTypography>
        {/* <SuiBox mt={1} mb={2}>
          <SuiTypography variant="button" color="text" fontWeight="regular">
            <SuiTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ fontWeight: "bold", color: ({ palette: { success } }) => success.main }}>
                arrow_upward
              </Icon>
            </SuiTypography>
            &nbsp;
            <SuiTypography variant="button" color="text" fontWeight="medium">
              24%
            </SuiTypography>{" "}
            المبلغ الخارج
          </SuiTypography>
        </SuiBox> */}
      </SuiBox>
      {users.map((user) => (
        <SuiBox p={2}>
          <TimelineItem
            color="success"
            icon="AccessAlarm"
            title={user.value}
            dateTime={user.created_at.substring(0, 10)}
          />
        </SuiBox>
      ))}
    </Card>
  );
}

export default OrdersOverview;
