/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import axios from "axios";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/users/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import EditMessageSuccess from "./edit_dialog";

function EditMessage() {
  const route = useLocation().pathname.split("/").slice(1);
  const [name, setName] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const fetchUsers = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_message/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["message"]["body"]);
    setCustomer(data["message"]["customer_id"]);
  };
  const getCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
    };
    // const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers", { headers });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCustomers(data["customers"]);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
    getCustomers();
  }, []);
  const { columns: prCols, rows: prRows } = projectsTableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit User</SuiTypography>
            </SuiBox>
            <div style={{ width: "400px", margin: "20px" }}>
              <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Body
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                    Choose Customer
                  </label>
                  <select
                    id="currency"
                    className="form-control"
                    style={{ height: "40px" }}
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                  >
                    {customers.map((user) => (
                      <option value={user.id}>{user.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <EditMessageSuccess body1={name} customer1={customer} />
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditMessage;
