import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

export default function LoadingDialog() {
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };

  function editUser() {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .post(
        "https://app.medical-clinic.tk/api/auth/user/update",
        {
          name: document.getElementById("name").value,
          email: document.getElementById("email").value,
          // password: document.getElementById("password").value,
          gender: document.getElementById("gender").value,
          phone: document.getElementById("phone").value,
          birthdate: document.getElementById("date").value,
          //   image: images,
        },
        { headers }
      )
      .then((response) => {
        handleClickOpenSuccess();
        console.log(response);
        console.log("success");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px" }}
        onClick={() => {
          handleClickOpenLoading();
          editUser();
        }}
      >
        {t("save")}
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("edit_profile_success")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate(`/profile`);
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
