/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
// import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactLoading from "react-loading";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import useStatusAdding from "hooks/status/useStatusAdding";
import { CCol, CForm, CFormInput, CRow } from "@coreui/react";

function AddStatus() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    name,
    setName,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    handleSubmit,
    validated,
  } = useStatusAdding();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="name"
                    feedbackInvalid="name is required"
                    id="categorynameAr"
                    label="name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </CCol>
              </CRow>
              {loading ? (
                <ReactLoading color="blue" height={50} width={50} type="spokes" />
              ) : (
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white", width: "15%" }}
                  type="submit"
                >
                  <span style={{ fontWeight: "bolder" }}>Add Status</span>
                </Button>
              )}
            </CForm>
            <Dialog
              open={dialogSuccess}
              onClose={() => {
                setDialogSuccess(false);
                navigate("/status");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Status Added Successfully</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    navigate("/Status");
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogError}
              onClose={() => {
                setDialogError(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Adding Status failed</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogError(false);
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddStatus;
