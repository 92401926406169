/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
// import { useParams } from "react-router-dom";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import EditOrderData from "./edit_dialog";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/users/data/projectsTableData";

function EditOrderMainData() {
  const route = useLocation().pathname.split("/").slice(1);
  const [order_code, setOrderCode] = useState([]);
  const [deivery_date, setDeliveryDate] = useState([]);
  const [phone, setPhone] = useState([]);
  const [customer_id, setCustomerID] = useState([]);
  const [cntainer_id, setContainerID] = useState([]);
  const [container, setContainer] = useState([]);
  const [status, setStatus] = useState([]);
  const getSpeceficOrder = async () => {
    // console.log(route[route.length - 1]);
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/orders/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setOrderCode(data["order"]["order_code"]);
    setDeliveryDate(data["order"]["delivery_date"]);
    setPhone(data["order"]["phone_number"]);
    setCustomerID(data["order"]["customer_id"]);
    setContainerID(data["order"]["container_id"]);
    setStatus(data["order"]["status"]);
  };
  const getContainers = async () => {
    const res = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await res.json();
    setContainer(data["containers"]);
  };
  useEffect(() => {
    getSpeceficOrder();
    getContainers();
  }, []);
  useEffect(() => {}, []);

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Order #{route[route.length - 1]}</SuiTypography>
            </SuiBox>
            <div style={{ width: "400px", margin: "20px" }}>
              <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Order Code
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setOrderCode(e.target.value)}
                  value={order_code}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Delivery Date
                </label>
                <input
                  required
                  type="date"
                  className="form-control"
                  name="name"
                  placeholder="Enter Code"
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  value={deivery_date}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Customer ID
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Address"
                  onChange={(e) => setCustomerID(e.target.value)}
                  value={customer_id}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Container ID
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setContainerID(e.target.value)}
                >
                  {container.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group  col-md-12">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px", color: "black" }}
                >
                  Choose Status
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="new">New</option>
                  <option value="received">received</option>
                  <option value="confirmed">Confirmed</option>
                </select>
              </div>

              <EditOrderData
                order_code1={order_code}
                phone1={phone}
                delivery_date1={deivery_date}
                customer_id1={customer_id}
                container_id1={cntainer_id}
                status1={status}
              />
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditOrderMainData;
