/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { useTranslation } from "react-i18next";
import AlertDialog from "./dialog";
// import DeleteLab from "./delete_lab";

function ReciveGoods() {
  // const  rows: [1];
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([0]);
  const [orders, setOrders] = useState([]);
  const [specificorder, setSpecificOrder] = useState([]);
  const [credit1, setCredit1] = useState("");
  const [id, setID] = useState([]);
  const [shopnumber, setShopNumber] = useState("");
  const [ordernumber, setOrder] = useState("");
  const [customer, setCustomer] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [item, setItem] = useState();
  const [deliverydate, setDeliveryDate] = useState();
  const [itemnumber, setItemNumber] = useState();

  function setMainAccount() {
    const MAIN_ACCOUNT = sessionStorage.getItem("main_account_recieve_goods");
    setCredit1(MAIN_ACCOUNT);
  }
  async function getSpecificOrder(id1) {
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/getSpecificOrder/${id1}`,
      { headers }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setSpecificOrder(data["order"]);
    setCustomer(specificorder["customer_id"]);
    setCustomerName(specificorder["customer_name"][0]["name"]);
    setPhoneNumber(specificorder["phone_number"]);
  }
  const getOrders = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/orders", {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["orders"]);
    console.log("users");
    console.log(users[0]);
    getSpecificOrder(users[0]["order_number"]);
  };
  async function getOrderDetails(id1) {
    const headers = { ContentType: `application/json` };
    const response = await fetch(`https://trust-cargo.co/anas_backend/api/getOrderDetails/${id1}`, {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["order_details"]);
  }

  // contants of my array
  const [itemarray, setItemArray] = useState([""]);
  const [photo, setPhoto] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([""]);
  const [qtytoctn, setQtyToCtn] = useState([""]);
  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  const [nw, setNW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);

  useEffect(() => {
    setMainAccount();
    getOrders();
  }, []);

  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        setCustomer();
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handlePhotoChange = (event, index) => {
    setPhoto((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDescChange = (event, index) => {
    setDescription1((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCategoryChange = (event, index) => {
    setCategory((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCTNChange = (event, index) => {
    setCTN((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleChange = (event) => {
    setOrder(event.target.value);
    setID(event.target.value);
    getSpecificOrder(id);
    setCustomer(specificorder["customer_id"]);
    setCustomerName(specificorder["customer_name"][0]["name"]);
    setPhoneNumber(specificorder["phone_number"]);
    getOrderDetails(id);
  };
  const ttocbm = cbm.map((e, index) => e * ctn[index]);
  const gw = nw.map((e, index) => e * ctn[index]);
  const ttoqty = ctn.map((e, index) => e * qtytoctn[index]);
  const amount = utoprice.map((e, index) => e * ttoqty[index]);
  const total_amount =
    amount.length !== 0
      ? amount.reduce((e1, e2) => {
          console.log("amount");
          console.log(amount);
          return e1 + e2;
        })
      : "0";
  useEffect(() => {
    const ite1 = [];
    orders.forEach((e) => {
      ite1.push(e.name);
    });
    const cate = [];
    orders.forEach((e) => {
      cate.push(e.category);
    });
    const ctnorders = [];
    orders.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    const qtytoctnorders = [];
    orders.forEach((e) => {
      qtytoctnorders.push(e.qty_ctn);
    });

    const desc1 = [];
    orders.forEach((e) => {
      desc1.push(e.description1);
    });
    const desc2 = [];
    orders.forEach((e) => {
      desc2.push(e.description2);
    });
    const utopriceorders = [];
    orders.forEach((e) => {
      utopriceorders.push(e.u_price);
    });
    const cbmorders = [];
    orders.forEach((e) => {
      cbmorders.push(e.cbm);
    });

    const nworders = [];
    orders.forEach((e) => {
      nworders.push(e.n_w);
    });

    const statusorders = [];
    orders.forEach((e) => {
      statusorders.push(e.status);
    });
    const notesorders = [];
    orders.forEach((e) => {
      notesorders.push(e.notes);
    });
    setItemArray(ite1);
    setCategory(cate);
    setCTN(ctnorders);
    setQtyToCtn(qtytoctnorders);
    setDescription1(desc1);
    setDescription2(desc2);
    setUtoPrice(utopriceorders);
    setCBM(cbmorders);
    setNW(nworders);
    setStatus(statusorders);
    setNotes(notesorders);
    console.log("amount");
    console.log(amount);
  }, [orders]);

  // const total_cbm = ttocbm.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_ctn = ctn.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_gw = gw.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Recive Goods</SuiTypography>
            </SuiBox>
            <div style={{ width: "600px", margin: "20px" }}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Order No.
                    </label>
                    <select
                      id="currency"
                      className="form-control"
                      style={{ height: "40px" }}
                      value={ordernumber}
                      onChange={handleChange}
                    >
                      {users.map((user) => (
                        <option value={user.order_number}>{user.order_number}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Customer
                    </label>
                    <input
                      required
                      onChange={(e) => setCustomer(e.target.value)}
                      value={customer}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Customer"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                      Phone No.
                    </label>
                    <input
                      required
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phonenumber}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Phone No."
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Debit 1
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Debit 1"
                      onChange={(e) => setCustomerName(e.target.value)}
                      value={customer_name}
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Debit 2
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Debit 2"
                      onChange={(e) => setItem(e.target.value)}
                      value={item}
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Credit 1
                    </label>
                    <input
                      required
                      onChange={(e) => setCredit1(e.target.value)}
                      value={credit1}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Credit 1"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Credit 2
                    </label>
                    <input
                      required
                      onChange={(e) => setItemNumber(e.target.value)}
                      value={itemnumber}
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Credit 2"
                    />
                  </div>
                </div> */}
              </div>
              <div className="row">
                {/* <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Items
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Items"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Amount
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Amount"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_amount}
                    />
                  </div>
                </div>
                {/* <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CTN
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CTN"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CBM
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CBM"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total GW
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total GW"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div> */}
              </div>

              <AlertDialog
                ItemArray={itemarray}
                photo={photo}
                desc1={description1}
                desc2={description2}
                category={category}
                ctn={ctn}
                qtytoctn={qtytoctn}
                totoqty={ttoqty}
                utoprice={utoprice}
                amount={amount}
                cbm={cbm}
                ttocbm={ttocbm}
                nw={nw}
                gw={gw}
                status={status}
                notes={notes}
                credit1={credit1}
                debet1={customer_name}
                id={id}
                total_amount1={total_amount}
                // shop_number1={shopnumber1}
                // shop_number2={shopnumber2}
                customer_id={customer}
                phone_number={phonenumber}
                order_number={ordernumber}
                delivery_date={deliverydate}
              />
            </div>
          </Card>
        </SuiBox>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Items</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ display: "flex" }}>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Item
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Photo
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Description1
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Description2
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Category
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      CTN
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      QTY/CTN
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      T/QTY
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      U/Price
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Amount
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      CBM
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      T/CBM
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      N.W
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      G.W
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Status
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                      Notes
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: "300px", minWidth: "300px" }} align="center">
                      Operations
                    </TableCell>
                  </Box>
                </TableRow>
              </TableHead>
              <TableBody style={{ display: "block " }}>
                {orders.map((vla, index) => (
                  <TableRow sx={{ minWidth: "100px" }}>
                    <Box style={{ overflowX: "auto" }}>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          id="item"
                          required
                          onChange={(e) => handleItemChange(e, index)}
                          value={itemarray[index]}
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Item"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="photo"
                          type="file"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Photo"
                          onChange={(e) => handlePhotoChange(e, index)}
                          value={photo[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="desc1"
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Description1"
                          onChange={(e) => handleDescChange(e, index)}
                          value={description1[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          id="desc2"
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Description2"
                          onChange={(e) => handleDesc2Change(e, index)}
                          value={description2[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          id="category"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Category"
                          onChange={(e) => handleCategoryChange(e, index)}
                          value={category[index]}
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="CTN"
                          onChange={(e) => handleCTNChange(e, index)}
                          value={ctn[index]}
                          id="ctn"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="QTY/CTN"
                          onChange={(e) => handleQTYTOCTNChange(e, index)}
                          value={qtytoctn[index]}
                          id="qtytoctn"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="T/QTY"
                          // onChange={(e) => handleTTOQTYChange(e, index)}
                          value={ttoqty[index]}
                          id="ttoqty"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="U/Price"
                          onChange={(e) => handleUTOPriceChange(e, index)}
                          value={utoprice[index]}
                          id="utoprice"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Amount"
                          // onChange={(e) => handleAmountChange(e, index)}
                          value={amount[index]}
                          id="amount"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="CBM"
                          onChange={(e) => handleCBMChange(e, index)}
                          value={cbm[index]}
                          id="cbm"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="T/CBM"
                          // onChange={(e) => handleTTOCBMChange(e, index)}
                          value={ttocbm[index]}
                          id="ttocbm"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="N.W"
                          onChange={(e) => handleNWChange(e, index)}
                          value={nw[index]}
                          id="nw"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="G.W"
                          // onChange={(e) => handleGWChange(e, index)}
                          value={gw[index]}
                          id="gw"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Status"
                          onChange={(e) => handleStatusChange(e, index)}
                          value={status[index]}
                          id="status"
                        />
                      </TableCell>
                      <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <input
                          required
                          type="text"
                          style={{ width: "150px", minWidth: "150px" }}
                          className="form-control"
                          name="name"
                          placeholder="Notes"
                          onChange={(e) => handleNotesChange(e, index)}
                          value={notes[index]}
                          id="notes"
                        />
                      </TableCell>
                      <TableCell
                        sx={{ width: "400px", minWidth: "400px" }}
                        style={{ flexDirection: "row" }}
                        align="space-around"
                      >
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            width: "100px",
                            minWidth: "100px",
                            fontSize: "10px",
                          }}
                          onClick={() => {
                            setItemArray((state) => [...state, ""]);
                            setPhoto((state) => [...state, ""]);
                            setDescription1((state) => [...state, ""]);
                            setDescription2((state) => [...state, ""]);
                            setCategory((state) => [...state, ""]);
                            setCTN((state) => [...state, ""]);
                            setQtyToCtn((state) => [...state, ""]);

                            setUtoPrice((state) => [...state, ""]);

                            setCBM((state) => [...state, ""]);

                            setNW((state) => [...state, ""]);

                            setStatus((state) => [...state, ""]);
                            setNotes((state) => [...state, ""]);

                            setRows((current) => [...current, current.length]);
                          }}
                        >
                          Add Item
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            width: "120px",
                            fontSize: "10px",
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
                            setItemArray((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setPhoto((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setDescription1((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setDescription1((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setDescription2((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setCategory((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setCTN((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setQtyToCtn((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);

                            setUtoPrice((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);

                            setCBM((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);

                            setNW((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);

                            setStatus((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                            setNotes((prevSate) => [
                              ...prevSate.slice(0, index),
                              ...prevSate.slice(index + 1),
                            ]);
                          }}
                        >
                          Remove Item
                        </Button>
                      </TableCell>
                    </Box>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SuiBox>
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReciveGoods;
