/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import styled from "styled-components";
import Slider from "react-slick";
import CardHome from "./card_home";
import "./loading.css";
import Footer from "examples/Footer";
import { Link, useNavigate } from "react-router-dom";

function HomeFive() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const Container = styled.div`
    background: #ffffff;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
  `;
  const List = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
  `;
  const [posts, setPosts] = useState([]);

  const fetchData = async () => {
    const response = await fetch("https://trust-cargo.co/anas_backend/api/posts");
    const data = await response.json();
    setPosts(data["posts"]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const slideSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    nextArrow: <button>Next Arrow</button>, // Replace with your custom Next Arrow component
    prevArrow: <button>Prev Arrow</button>, // Replace with your custom Prev Arrow component
  };

  return (
    // <>
    <div>
      <div style={{ zIndex: "3" }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Welcome To Anas Jweiles
            </Typography>
            <Button
              onClick={() => {
                navigate("/authentication/sign-in");
              }}
              color="inherit"
            >
              Login
            </Button>
          </Toolbar>
        </AppBar>{" "}
      </div>
      {loading ? (
        <div className="loader-container">
          <div className="spinner" />
        </div>
      ) : (
        <div className="">
          <div className="fluid-post-wrapper p-t-xs-15 p-t-sm-30">
            <div className="container-fluid p-l-md-30 p-r-md-30">
              <div className="row">
                <div className="col-xl-4 col-lg-6">
                  <Slider {...slideSettings} className="axil-content axil-post-carousel">
                    {posts.length > 0 &&
                      posts[0].images.map((imageData) => (
                        <div className="item" style={{ position: "relative" }}>
                          <img
                            key={imageData.id}
                            src={imageData.image}
                            alt=""
                            width={600}
                            height={525}
                          />
                          <div style={{ position: "absolute", top: "50%", left: "20%" }}>
                            {" "}
                            <div
                              className="axil-media-bottom"
                              onClick={() => navigate(`/single-post/${posts[0].id}`)}
                            >
                              <h4
                                className=" hover-line m-b-xs-0"
                                style={{ fontSize: "20px", color: "white", cursor: "pointer" }}
                              >
                                <a style={{ textDecoration: "none" }}>{posts[0].body}</a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
                <div className="col-xl-8 col-lg-6">
                  <div
                    className=""
                    // style={{ maxHeight: "1000px", overflowY: "scroll" }}
                  >
                    <div className="row gutter-40">
                      {posts.slice(0, 6).map((data) => (
                        <div className="col-xl-4 col-sm-6" key={data.slug}>
                          {/* Use CardHome component to render post */}
                          <CardHome
                            title={data.title}
                            desc={data.body}
                            images={data.images}
                            id={data.id}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                  {posts.slice(6, posts.length).map((data) => (
                    <div className="col-xl-3 col-sm-6" key={data.slug}>
                      {/* Use CardHome component to render post */}
                      <CardHome
                        title={data.title}
                        desc={data.body}
                        images={data.images}
                        id={data.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    //   <Footer />
    // </>
  );
}

export default HomeFive;
