import useFormValidation from "hooks/general/useFormValidation";
import { useState } from "react";
import axios from "axios";

const useContainerAdding = () => {
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [status, setStatus] = useState();
  const [estimatedArrrivalDate, setEstimatedArrrivalDate] = useState();
  const [loadingDate, setLodaingDate] = useState();
  const [telexStatus, setTelexStatus] = useState();
  const [note, setNote] = useState();
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  function AddContainer() {
    setLoading(true);
    // const token = sessionStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      ContentType: "application/json",
    };

    const formData = new FormData();
    formData.append("name", name);
    formData.append("number", number);
    formData.append("status", status);
    formData.append("note", note);
    formData.append("loading_date", loadingDate);
    formData.append("telex_status", telexStatus);
    formData.append("estimated_arrival_date", estimatedArrrivalDate);
    axios
      .post("https://trust-cargo.co/anas_backend/api/add_container", formData, { headers })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(AddContainer);

  return {
    handleSubmit,
    validated,
    name,
    setName,
    number,
    setNumber,
    status,
    setStatus,
    estimatedArrrivalDate,
    setEstimatedArrrivalDate,
    loadingDate,
    setLodaingDate,
    telexStatus,
    setTelexStatus,
    note,
    setNote,
    loading,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
  };
};

export default useContainerAdding;
