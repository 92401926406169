import { useState } from "react";
import axios from "axios";
import useFormValidation from "hooks/general/useFormValidation";

const useStatusAdding = () => {
  const [name, setName] = useState();
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  function AddStatus() {
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const formData = new FormData();
    formData.append("name", name);
    axios
      .post("https://trust-cargo.co/anas_backend/api/add_status", formData, { headers })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(AddStatus);

  return {
    name,
    setName,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    handleSubmit,
    validated,
  };
};

export default useStatusAdding;
