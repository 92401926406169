/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
// import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import ReactLoading from "react-loading";
import SuiTypography from "components/SuiTypography";

// import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
// import Select from "@mui/material/Select";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CButton, CCol, CForm, CFormInput, CFormSelect, CRow } from "@coreui/react";
import useContainerAdding from "hooks/containers/useContainerAdding";

import { Spinner } from "react-bootstrap";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

function AddContainer() {
  // const [name, setName] = useState();
  // const [number, setNumber] = useState();
  // const [status, setStatus] = useState();
  // const [estimatedArrrivalDate, setEstimatedArrrivalDate] = useState();
  // const [loadingDate, setLodaingDate] = useState();
  // const [telexStatus, setTelexStatus] = useState();
  // const [note, setNote] = useState();

  const {
    handleSubmit,
    validated,
    name,
    setName,
    number,
    setNumber,
    status,
    setStatus,
    estimatedArrrivalDate,
    setEstimatedArrrivalDate,
    loadingDate,
    setLodaingDate,
    telexStatus,
    setTelexStatus,
    note,
    setNote,
    loading,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
  } = useContainerAdding();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <CForm
              className="row g-3 needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="name"
                    feedbackInvalid="name is required"
                    id="categoryNameAr"
                    label="name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md={4}>
                  <CFormInput
                    type="text"
                    placeholder="number"
                    feedbackInvalid="number is required"
                    id="categorynumberAr"
                    label="number"
                    required
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                    value={number}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormSelect
                    id="productCategory"
                    label="status"
                    feedbackInvalid="Status required"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  >
                    <option selected="" value="">
                      {" "}
                      Choose status ...
                    </option>

                    <option value="unloaded">Unloaded</option>
                    <option value="on progress">On progress</option>
                    <option value="delivered">Delivered</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="date"
                    placeholder="loading date"
                    feedbackInvalid="loading date is required"
                    id="categoryloading dateAr"
                    label="loading date"
                    required
                    onChange={(e) => {
                      setLodaingDate(e.target.value);
                    }}
                    value={loadingDate}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="date"
                    placeholder="estimated arival date"
                    feedbackInvalid="estimated arival date is required"
                    id="categoryestimated arival dateAr"
                    label="estimated arival date"
                    required
                    onChange={(e) => {
                      setEstimatedArrrivalDate(e.target.value);
                    }}
                    value={estimatedArrrivalDate}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol md={4}>
                  <CFormSelect
                    id="productCategory"
                    label="Telex Status"
                    feedbackInvalid="Telex Status required"
                    value={telexStatus}
                    onChange={(e) => setTelexStatus(e.target.value)}
                    required
                  >
                    <option selected="" value="">
                      {" "}
                      Choose telex status ...
                    </option>

                    <option value="released">Released</option>
                    <option value="not_yet">Not yet</option>
                  </CFormSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={4}>
                  <CFormInput
                    type="text"
                    placeholder="note"
                    feedbackInvalid="note is required"
                    id="categorynoteAr"
                    label="note"
                    required
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    value={note}
                  />
                </CCol>
              </CRow>
              {loading ? (
                <ReactLoading color="blue" height={50} width={50} type="spokes" />
              ) : (
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white", width: "15%" }}
                  type="submit"
                >
                  <span style={{ fontWeight: "bolder" }}>Add Container</span>
                </Button>
              )}
            </CForm>
            <Dialog
              open={dialogSuccess}
              onClose={() => {
                setDialogSuccess(false);
                navigate("/containers");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Container Added Successfully</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    navigate("/containers");
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogError}
              onClose={() => {
                setDialogError(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Adding container failed</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogError(false);
                  }}
                  autoFocus
                >
                  {t("ok")}
                </Button>
              </DialogActions>
            </Dialog>

            {/* <div style={{ width: "400px", margin: "20px" }}>
              <div className="form-group  col-md-4 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Name
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="number"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Number
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  type="text"
                  className="form-control"
                  name="number"
                  placeholder="Number"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="status"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Status
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="unloaded">Unloaded</option>
                  <option value="on progress">On progress</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="number"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Loading date
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setLodaingDate(e.target.value)}
                  value={loadingDate}
                  type="date"
                  className="form-control"
                  name="loading_date"
                  placeholder="Loading date"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="number"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Estimated arrival date
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setEstimatedArrrivalDate(e.target.value)}
                  value={estimatedArrrivalDate}
                  type="date"
                  className="form-control"
                  name="estimated_arrival_date"
                  placeholder="Estimated arrival date"
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="telex_status"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Telex status
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setTelexStatus(e.target.value)}
                >
                  <option value="released">Released</option>
                  <option value="not_yet">Not yet</option>
                </select>
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="note"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Note
                </label>
                <input
                  required
                  id="value"
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  type="text"
                  className="form-control"
                  name="notes"
                  placeholder="Note"
                />
              </div>
              <AlertDialog
                name1={name}
                number1={number}
                status1={status}
                note1={note}
                loadingDate1={loadingDate}
                telexStatus1={telexStatus}
                estimatedArrivalDate1={estimatedArrrivalDate}
              />
            </div> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddContainer;
