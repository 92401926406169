import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import PropTypes from "prop-types";
import SuiTypography from "components/SuiTypography";
import { useTranslation } from "react-i18next";

export default function DeleteReservation({ id, fetch }) {
  const [dialogdelete, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const dialogtrue = () => {
    setOpen(true);
  };
  const dialogfalse = () => {
    setOpen(false);
  };
  DeleteReservation.propTypes = {
    id: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired,
  };
  function deleteUser(i) {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .delete(`https://app.medical-clinic.tk/api/reservations/${i}/delete`, { headers })
      .then((response) => {
        console.log(response);
        fetch();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <SuiTypography
        component="a"
        href="#"
        variant="caption"
        color="primary"
        fontWeight="medium"
        margin="15px"
        onClick={() => {
          dialogtrue();
        }}
      >
        {t("delete")}
      </SuiTypography>
      <Dialog
        open={dialogdelete}
        onClose={dialogfalse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("delete_reservation_sure")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
Let Google help apps determine location. This means sending anonymous location data to
Google, even when no apps are running.
</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              deleteUser(id);
              dialogfalse();
            }}
            autoFocus
          >
            {t("yes")}
          </Button>
          <Button
            onClick={() => {
              dialogfalse();
            }}
            autoFocus
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
