/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import * as React from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
// import { useNavigate } from "react-router-dom";
import projectsTableData from "layouts/users/data/projectsTableData";
import { useTranslation } from "react-i18next";
import EditButtonPassword from "./button_edit";

function EditPasswordUser() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const { columns: prCols, rows: prRows } = projectsTableData;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">{t("edit_password")}</SuiTypography>
            </SuiBox>
            <div style={{ width: "200px", marginLeft: "20px", color: "green" }}>
              <TextField
                id="password"
                label={t("password")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  style: {
                    minWidth: "50rem",
                    width: "50rem",
                    fontSize: 16,
                    height: "2rem",
                  },
                }}
                style={{ width: "50rem", fontSize: "15px", margin: "30px" }}
              />
              <EditButtonPassword />
            </div>
          </Card>
        </SuiBox>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SuiBox>
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditPasswordUser;
