/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

import useContainers from "hooks/containers/useContainers";
import useCustomers from "hooks/customers/useCustomers";
import useFormValidation from "hooks/general/useFormValidation";
import useStatus from "hooks/status/useStatus";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useOrderAdding = () => {
  const navigate = useNavigate();
  const { customers } = useCustomers();
  const { containers } = useContainers();
  const { status: statusarray } = useStatus();
  const [customer, setCustomer] = useState("");
  const [container, setContainer] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [shopNumber, setShopNumber] = useState("");
  const [orderCode, setOrderCode] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [totalCTN, setTotalCTN] = useState(0);
  const [totalGW, setTotalGW] = useState(0);
  const [totalTCBM, setTotalTCBM] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rows, setRows] = useState([
    {
      our_item: "",
      item_number: "",
      photo: "",
      description2: "",
      ctn: "",
      qty_ctn: "",
      t_qty: "",
      u_price: "",
      amount: "",
      cbm: "",
      t_cbm: "",
      n_w: "",
      g_w: "",
      status: "",
      notes: "",
    },
  ]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        our_item: "",
        item_number: "",
        photo: "",
        description2: "",
        ctn: "",
        qty_ctn: "",
        t_qty: "",
        u_price: "",
        amount: "",
        cbm: "",
        t_cbm: "",
        n_w: "",
        g_w: "",
        status: "",
        notes: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedRow = [...rows];
    updatedRow[index][field] = value;
    switch (field) {
      case "ctn":
        updatedRow[index] = {
          ...updatedRow[index],
          t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
          t_cbm: updatedRow[index].ctn * updatedRow[index].ctn,
          g_w: updatedRow[index].n_w * updatedRow[index].ctn,
        };
        break;
      case "qty_ctn":
        updatedRow[index] = {
          ...updatedRow[index],
          t_qty: updatedRow[index].ctn * updatedRow[index].qty_ctn,
        };
        break;
      case "cbm":
        updatedRow[index] = {
          ...updatedRow[index],
          t_cbm: updatedRow[index].ctn * updatedRow[index].cbm,
        };
        break;
      case "n_w":
        updatedRow[index] = {
          ...updatedRow[index],
          g_w: updatedRow[index].n_w * updatedRow[index].ctn,
        };
        break;
      default:
        break;
    }
    setRows(updatedRow);
  };

  const calculateTotals = () => {
    const toCTN = rows.reduce((total, e) => {
      return total + Number(e.ctn);
    }, 0);
    const toGW = rows.reduce((total, e) => {
      return total + Number(e.g_w);
    }, 0);
    const toTCBM = rows.reduce((total, e) => {
      return total + Number(e.t_cbm);
    }, 0);
    const toAmount = rows.reduce((total, e) => {
      return total + Number(e.amount);
    }, 0);

    console.log(toCTN, toTCBM, toAmount, toGW);

    setTotalCTN(toCTN.toFixed(2));
    setTotalTCBM(toTCBM.toFixed(2));
    setTotalAmount(toAmount.toFixed(2));
    setTotalGW(toGW.toFixed(2));
  };

  useEffect(() => {
    calculateTotals();
  }, [rows]);

  function addOrder() {
    const formData = new FormData();
    setOrderCode(customer + shopNumber);
    formData.append("delivery_date", deliveryDate);
    formData.append("phone_number", phone);
    formData.append("order_code", orderCode);
    formData.append("customer_id", customer);
    formData.append("container_id", container);
    rows.forEach((row, index) => {
      formData.append(`our_item[${index}]`, customer + shopNumber + -+(index + 1));
      formData.append(`item_number[${index}]`, row.item_number);
      formData.append(`ctn[${index}]`, row.ctn);
      formData.append(`amount[${index}]`, row.amount);
      formData.append(`cbm[${index}]`, row.cbm);
      formData.append(`status[${index}]`, row.status);
      formData.append(`notes[${index}]`, row.notes);
      formData.append(`qty_ctn[${index}]`, row.qty_ctn);
      formData.append(`t_qty[${index}]`, row.t_qty);
      formData.append(`u_price[${index}]`, row.u_price);
      formData.append(`t_cbm[${index}]`, row.t_cbm);
      formData.append(`n_w[${index}]`, row.n_w);
      formData.append(`g_w[${index}]`, row.g_w);
      formData.append(`category[${index}]`, "category");
      formData.append(`description1[${index}]`, row.description2);
      formData.append(`description2[${index}]`, row.description2);
      formData.append(`photo[${index}]`, row.photo);
    });

    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/add_order";

    axios
      .post(url, formData, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(addOrder);

  return {
    handleSubmit,
    validated,
    containers,
    customers,
    statusarray,
    customer,
    setCustomer,
    container,
    setContainer,
    dialogOpen,
    setDialogOpen,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    shopNumber,
    setShopNumber,
    orderCode,
    setOrderCode,
    phone,
    setPhone,
    deliveryDate,
    setDeliveryDate,
    totalCTN,
    totalGW,
    totalTCBM,
    totalAmount,
    rows,
    setRows,
    navigate,
    handleFieldChange,
    handleAddRow,
    handleDeleteRow,
  };
};

export default useOrderAdding;
