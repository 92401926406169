/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Button from "@mui/material/Button";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";
import * as React from "react";
// Soft UI Dashboard React components

// Soft UI Dashboard React examples
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditButtonPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  function editdrug() {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .post(
        "https://app.medical-clinic.tk/api/auth/user/password/update",
        {
          password: document.getElementById("password").value,
        },
        { headers }
      )
      .then((response) => {
        console.log(response);
        console.log("success");
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px" }}
        onClick={() => {
          editdrug();
        }}
      >
        {t("save")}
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("edit_password_success")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
        Let Google help apps determine location. This means sending anonymous location data to
        Google, even when no apps are running.
      </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* navigate("/add_user"); */}
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/profile");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("add_setting_empty")}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
        Let Google help apps determine location. This means sending anonymous location data to
        Google, even when no apps are running.
      </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditButtonPassword;
