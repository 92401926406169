import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStatus = () => {
  const [status, setStatus] = useState([]);
  const [loading, setloading] = useState(false);
  // const [search, setSearch] = useState(false);
  // function changeboolvalue() {
  //   setSearch(false);
  // }

  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/status");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setStatus(data["status"]);
    setloading(false);
  };
  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);
  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "delete_status",
    fetchData
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  return {
    status,
    loading,
    fetchData,
    navigate,
    t,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  };
};

export default useStatus;
