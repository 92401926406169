/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function TestUsers() {
  const [users, setUsers] = useState([]);
  // const [search, setSearch] = useState(false);
  // function changeboolvalue() {
  //   setSearch(false);
  // }

  const fetchData = async () => {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch(
      `https://app.medical-clinic.tk/api/users?search=${
        document.getElementById("search_id").value
      }`,
      { headers }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["data"]);
    console.log("data");
    console.log(data);
    console.log(document.getElementById("search_id").value);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">{t("ussdfsdfsders")}</SuiTypography>
              <SuiBox pr={1}>
                <TextField
                  id="search_id"
                  placeholder="Type here..."
                  onChange={fetchData}
                  type="input"
                  sx={{ width: 220, margin: "15px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ height: "2rem", width: "2rem" }}>
                        <Search style={{ height: "1.2rem", width: "1.5rem" }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{ width: "15rem", margin: "15px" }}
                />
              </SuiBox>
            </SuiBox>
            <div style={{ width: "200px", margin: "20px", color: "green" }}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => {
                  navigate("/add_user");
                }}
              >
                {t("add_user")}
              </Button>
            </div>
            dsfhjdlsk
            {/* </TableContainer> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TestUsers;
