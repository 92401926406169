import { useState } from "react";
import axios from "axios";
import useFormValidation from "hooks/general/useFormValidation";

const usePostAdding = () => {
  const [files1, setFiles1] = useState([]);
  const [body, setBody] = useState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  function handleChange(e) {
    const fies = Array(e.target.files.length).fill("");
    setFiles(fies.map((item, index) => URL.createObjectURL(e.target.files[index])));
    setFiles1(fies.map((item, index) => e.target.files[index]));
  }

  function addPost() {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("body", body);
    files1.map((item, index) => formdata.append(`image[${index}]`, files1[index]));
    const headers = { ContentType: `application/json` };
    axios
      .post("https://trust-cargo.co/anas_backend/api/add_post", formdata, {
        headers,
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(addPost);

  return {
    files1,
    setFiles1,
    body,
    setBody,
    files,
    setFiles,
    handleChange,
    handleSubmit,
    validated,
    loading,
    dialogError,
    dialogSuccess,
    setDialogError,
    setDialogSuccess,
  };
};

export default usePostAdding;
