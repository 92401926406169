/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  TableContainer,
  // Paper,
} from "@mui/material";
import close from "assets/images/close.png";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import CancelIcon from "@mui/icons-material/Cancel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormInput,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import useOrderEditing from "hooks/orders/useOrderEditing";
import broken_image from "../../assets/images/image.png";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/users/data/projectsTableData";

function EditOrder() {
  const route = useLocation().pathname.split("/").slice(1);
  const {
    handleFieldChange,
    rows,
    loading,
    dialogError,
    setDialogError,
    dialogSuccess,
    setDialogSuccess,
    editOrder,
  } = useOrderEditing(route[route.length - 1]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Order #{route[route.length - 1]}</SuiTypography>
            </SuiBox> */}
            <div className="row">
              <div className="form-group col-md-4">
                <Button
                  variant="contained"
                  style={{ margin: "20px", color: "white" }}
                  onClick={() => {
                    editOrder();
                  }}
                >
                  Confirm Order
                </Button>
                {/* <EditOrderData
                  order_id1={route[route.length - 1]}
                  order_code1={ordercode}
                  our_item1={itemarray}
                  ItemArray={itemarray}
                  photo={photo}
                  desc1={description1}
                  desc2={description2}
                  category1={category}
                  ctn={ctn}
                  qtytoctn={qtytoctn}
                  totoqty={ttoqty}
                  utoprice={utoprice}
                  amount={amount}
                  cbm={cbm}
                  ttocbm={ttocbm}
                  nw={nw}
                  gw={gw}
                  status={status}
                  notes={notes}
                  container_id={container_id}
                /> */}
              </div>
            </div>

            {/* <div style={{ width: "1000px", marginLeft: "20px", marginTop: "10px", color: "green" }}> */}
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <TableContainer className="mt-2">
                <CTable bordered>
                  <CTableHead color="light">
                    <CTableRow style={{ fontSize: "18px", position: "sticky" }}>
                      <CTableHeaderCell className="text-center">O.Code</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Container</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Our Item</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Item No.</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Image</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Desc En.</CTableHeaderCell>
                      <CTableHeaderCell className="text-center"> CTN</CTableHeaderCell>
                      <CTableHeaderCell className="text-center"> QTY/CTN</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">T/QTY</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">UPrice</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">CBM</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">T/CBM</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">N.W</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">G.W</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Notes</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {rows.map((row, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        <CTableDataCell className="text-center">
                          <input
                            id="item"
                            required
                            onChange={(e) => handleFieldChange(index, "order_code", e.target.value)}
                            value={row.order_code}
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="Order Code"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            id="item"
                            required
                            // onChange={(e) => handleItemChange(e, index)}
                            value={row.container_id}
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="Container name"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            id="item"
                            required
                            onChange={(e) => handleFieldChange(index, "our_item", e.target.value)}
                            value={row.our_item}
                            type="text"
                            style={{ width: "90px", minWidth: "90px" }}
                            className="form-control"
                            name="name"
                            placeholder="Our Item"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            id="item"
                            required
                            onChange={(e) =>
                              handleFieldChange(index, "item_number", e.target.value)
                            }
                            value={row.item_number}
                            type="text"
                            style={{ width: "90px", minWidth: "90px" }}
                            className="form-control"
                            name="name"
                            placeholder="Item Number"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <CFormInput
                            type="file"
                            id="categoryPic"
                            feedbackInvalid="Image required"
                            required
                            onChange={(e) => handleFieldChange(index, "photo", e.target.files[0])}
                          />
                          <div
                            className="form-group d-flex flex-row  col-md-12 d-grid gap-2"
                            style={{ marginTop: "20px" }}
                          >
                            {typeof row.photo === "string" || typeof row.photo === "undefined" ? (
                              <div style={{ position: "relative" }}>
                                <img
                                  src={row.photo}
                                  style={{ height: "60px" }}
                                  alt=""
                                  onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = broken_image;
                                  }}
                                />
                              </div>
                            ) : (
                              <img
                                src={URL.createObjectURL(row.photo)}
                                style={{ height: "60px" }}
                                alt=""
                              />
                            )}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            id="desc2"
                            type="text"
                            style={{ width: "200px", minWidth: "200px" }}
                            className="form-control"
                            name="name"
                            placeholder="Desc English"
                            onChange={(e) =>
                              handleFieldChange(index, "description2", e.target.value)
                            }
                            value={row.description2}
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="CTN"
                            onChange={(e) => handleFieldChange(index, "ctn", e.target.value)}
                            value={row.ctn}
                            id="ctn"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="QTY/CTN"
                            onChange={(e) => handleFieldChange(index, "qty_ctn", e.target.value)}
                            value={row.qty_ctn}
                            id="qtytoctn"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            readOnly
                            placeholder="T/QTY"
                            // onChange={(e) => handleTTOQTYChange(e, index)}
                            value={row.t_qty}
                            id="ttoqty"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            id="desc2"
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="UPrice"
                            onChange={(e) => handleFieldChange(index, "u_price", e.target.value)}
                            value={row.u_price}
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="CBM"
                            onChange={(e) => handleFieldChange(index, "cbm", e.target.value)}
                            value={row.cbm}
                            id="cbm"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="T/CBM"
                            readOnly
                            // onChange={(e) => handleTTOCBMChange(e, index)}
                            value={row.t_cbm}
                            id="ttocbm"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          {" "}
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="N.W"
                            onChange={(e) => handleFieldChange(index, "n_w", e.target.value)}
                            value={row.n_w}
                            id="nw"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="G.W"
                            readOnly
                            // onChange={(e) => handleGWChange(e, index)}
                            value={row.g_w}
                            id="gw"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="Status"
                            onChange={(e) => handleFieldChange(index, "status", e.target.value)}
                            value={row.status}
                            id="status"
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <input
                            required
                            type="text"
                            style={{ width: "150px", minWidth: "150px" }}
                            className="form-control"
                            name="name"
                            placeholder="Notes"
                            onChange={(e) => handleFieldChange(index, "notes", e.target.value)}
                            value={row.notes}
                            id="notes"
                          />
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </TableContainer>
              <Dialog
                open={dialogSuccess}
                onClose={() => {
                  setDialogSuccess(false);
                  navigate("/orders");
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Order Edited Successfully</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      navigate("/orders");
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={dialogError}
                onClose={() => {
                  setDialogError(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Editing order failed</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogError(false);
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
            </SuiBox>
            {/* </div> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditOrder;
