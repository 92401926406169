/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import { Box, Table, TableCell, TableHead, TableBody, TableRow } from "@mui/material";
import close from "assets/images/close.png";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function ViewContainer() {
  const route = useLocation().pathname.split("/").slice(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState();

  const getSpeceficOrder = async () => {
    // console.log(route[route.length - 1]);
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_container/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setOrders(data["order_details"]);
    setLoading(false);
  };
  const [ordercode, setOrderCode] = useState([""]);
  const [container_id, setContainerID] = useState([""]);
  const [customer_id, setCustomerID] = useState([""]);
  const [itemarray, setItemArray] = useState([""]);
  const [itemnumber, setItemNumber] = useState([""]);
  const [photo, setPhoto] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([]);
  const [amount, setAmount] = useState([]);
  const [t_cbm, setTCBm] = useState([]);
  const [qtytoctn, setQtyToCtn] = useState([""]);

  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  const initialValue = 0;

  // const [amount, setAmount] = useState([""]);
  // eslint-disable-next-line arrow-body-style

  const ttocbm = cbm.map((e, index) => {
    return e * ctn[index];
  });

  // const total_cbm = ttocbm.reduce((e1, e2) => {
  //   return e1 + e2;
  // });

  const ttoqty = ctn.map((e, index) => {
    return e * qtytoctn[index];
  });

  // const total_amount = Number.isNaN(amount)
  //   ? amount.reduce((e1, e2) => {
  //       return e1 + e2;
  //     }, initialValue)
  //   : 0;
  const [nw, setNW] = useState([""]);
  // const [gw, setGW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);
  const gw = nw.map((e, index) => {
    return e * ctn[index];
  });

  const total_gw =
    gw !== 0
      ? gw.reduce((e1, e2) => {
          return e1 + e2;
        }, initialValue)
      : "0";
  console.log(total_gw, "total gw");
  const handleOrderCodeChange = (event, index) => {
    setOrderCode((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleContainerIDChange = (event, index) => {
    setContainerID((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCustomerIDChange = (event, index) => {
    setContainerID((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleItemNumberChange = (event, index) => {
    setItemNumber((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handlePhotoChange = (event, index) => {
    setPhoto((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDescChange = (event, index) => {
    setDescription1((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCategoryChange = (event, index) => {
    setCategory((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCTNChange = (event, index) => {
    setCTN((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOQTYChange = (event, index) => {
  //   setTtoQty((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };

  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleAmountChange = (event, index) => {
  //   setAmount((state) =>
  //     state.map((el, index1) => {
  //       console.log("enver", event.target.value);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOCBMChange = (event, index) => {
  //   setTtoCBM((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleGWChange = (event, index) => {
  //   setGW((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const total_ctn =
    ctn.length !== 0
      ? ctn.reduce((e1, e2) => {
          console.log("amount");
          console.log(amount);
          return Number(e1) + Number(e2);
        })
      : "0";
  const total_t_tbm =
    t_cbm.length !== 0
      ? t_cbm.reduce((e1, e2) => {
          console.log("amount");
          console.log(amount);
          return Number(e1) + Number(e2);
        })
      : "0";
  const total_amount =
    amount.length !== 0
      ? amount.reduce((e1, e2) => {
          console.log("amount");
          console.log(amount);

          // Convert values to numbers and replace NaN or null with 0
          const num1 = Number(e1) || 0;
          const num2 = Number(e2) || 0;

          return num1 + num2;
        })
      : "0";

  console.log(total_amount);

  useEffect(() => {
    setLoading(true);
    getSpeceficOrder();
  }, []);
  useEffect(() => {
    const ordercode1 = [];
    orders.forEach((e) => {
      ordercode1.push(e.order_code);
    });
    const container_id1 = [];
    orders.forEach((e) => {
      container_id1.push(e.container_name);
    });
    const customer_id1 = [];
    orders.forEach((e) => {
      customer_id1.push(e.customer_id);
    });
    const ite1 = [];
    orders.forEach((e) => {
      ite1.push(e.our_item);
    });
    const cate = [];
    orders.forEach((e) => {
      cate.push(e.category);
    });
    const ctnorders = [];
    orders.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    const amountOrders = [];
    orders.forEach((e) => {
      amountOrders.push(e.amount);
    });
    const t_cbmorders = [];
    orders.forEach((e) => {
      t_cbmorders.push(e.t_cbm);
    });
    const qtytoctnorders = [];
    orders.forEach((e) => {
      qtytoctnorders.push(e.qty_ctn);
    });

    const desc1 = [];
    orders.forEach((e) => {
      desc1.push(e.description1);
    });
    const desc2 = [];
    orders.forEach((e) => {
      desc2.push(e.description2);
    });
    const photo1 = [];
    orders.forEach((e) => {
      photo1.push(e.photo);
    });
    const utopriceorders = [];
    orders.forEach((e) => {
      utopriceorders.push(e.u_price);
    });
    const cbmorders = [];
    orders.forEach((e) => {
      cbmorders.push(e.cbm);
    });

    const nworders = [];
    orders.forEach((e) => {
      nworders.push(e.n_w);
    });

    const statusorders = [];
    orders.forEach((e) => {
      statusorders.push(e.status);
    });
    const notesorders = [];
    orders.forEach((e) => {
      notesorders.push(e.notes);
    });
    setOrderCode(ordercode1);
    setContainerID(container_id1);
    setCustomerID(customer_id1);
    setItemArray(ite1);
    setCategory(cate);
    setPhoto(photo1);
    setCTN(ctnorders);
    setAmount(amountOrders);
    setTCBm(t_cbmorders);
    setQtyToCtn(qtytoctnorders);
    setDescription1(desc1);
    setDescription2(desc2);
    setUtoPrice(utopriceorders);
    setCBM(cbmorders);
    setNW(nworders);
    setStatus(statusorders);
    setNotes(notesorders);
  }, [orders]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">View Container #{route[route.length - 1]}</SuiTypography>
            </SuiBox> */}
            {/* <div style={{ width: "1000px", marginLeft: "20px", marginTop: "10px", color: "green" }}> */}
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {" "}
              <div className="row" style={{ width: "600px", margin: "20px", color: "" }}>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CTN
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CTN"
                      style={{ width: "100px", minWidth: "100px" }}
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_ctn}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total G.w
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_gw}
                      style={{ width: "100px", minWidth: "100px" }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total T_CBM
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_t_tbm}
                      style={{ width: "100px", minWidth: "100px" }}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Amount
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_amount}
                      style={{ width: "100px", minWidth: "100px" }}
                    />
                  </div>
                </div>
              </div>
              <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Order Code
                      </TableCell>
                    </Box>
                    {/* <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Container ID
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Customer ID
                      </TableCell>
                    </Box> */}
                    {/* <Box>
                      <TableCell align="start">Our Item</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">Item Number</TableCell>
                    </Box> */}
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Photo
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "180px", minWidth: "180px" }} align="start">
                        Desc English
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">CTN</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">QTY/CTN</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">T/QTY</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">CBM</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">T/CBM</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">N.W</TableCell>
                    </Box>
                    <Box>
                      <TableCell align="start">G.W</TableCell>
                    </Box>
                    {/* <Box>
                      <TableCell align="start">Amount </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "180px", minWidth: "180px" }} align="start">
                        Status
                      </TableCell>
                    </Box> */}
                    <Box>
                      <TableCell align="start">Notes</TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <div
                    style={{
                      width: "1000",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <progress className="pure-material-progress-circular" />
                  </div>
                ) : (
                  <TableBody style={{ display: "block " }}>
                    {orders.map((vla, index) => (
                      <TableRow key={`${index}key`} sx={{ minWidth: "100px" }}>
                        <Box style={{ overflowX: "auto" }}>
                          <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleOrderCodeChange(e, index)}
                              value={ordercode[index]}
                              type="text"
                              style={{ width: "90px", minWidth: "90px" }}
                              className="form-control"
                              name="name"
                              placeholder="Order Code"
                            />
                          </TableCell>
                          {/* <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleContainerIDChange(e, index)}
                              value={container_id[index]}
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Container ID"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleCustomerIDChange(e, index)}
                              value={customer_id[index]}
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                            />
                          </TableCell> */}
                          {/* <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleItemChange(e, index)}
                              value={itemarray[index]}
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Our Item"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleItemChange(e, index)}
                              value={itemarray[index]}
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Item Number"
                            />
                          </TableCell> */}
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            {photo[index] === "https://trust-cargo.co/anas_backend/storage/" ? (
                              <img
                                src={close}
                                style={{ width: "90px", height: "60px" }}
                                alt="Default Image"
                              />
                            ) : (
                              <img
                                src={photo[index]}
                                style={{ width: "90px", height: "60px" }}
                                alt=""
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              id="desc2"
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="Desc English"
                              onChange={(e) => handleDesc2Change(e, index)}
                              value={description2[index]}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="CTN"
                              onChange={(e) => handleCTNChange(e, index)}
                              value={ctn[index]}
                              id="ctn"
                            />
                          </TableCell>
                          {/* <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            <input
                              required
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="CTN"
                              // onChange={(e) => handleCTNChange(e, index)}
                              value={total_ctn}
                              id="ctn"
                            />
                          </TableCell> */}
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="QTY/CTN"
                              onChange={(e) => handleQTYTOCTNChange(e, index)}
                              value={qtytoctn[index]}
                              id="qtytoctn"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="T/QTY"
                              // onChange={(e) => handleTTOQTYChange(e, index)}
                              value={ttoqty[index]}
                              id="ttoqty"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="CBM"
                              onChange={(e) => handleCBMChange(e, index)}
                              value={cbm[index]}
                              id="cbm"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="T/CBM"
                              // onChange={(e) => handleTTOCBMChange(e, index)}
                              value={ttocbm[index]}
                              id="ttocbm"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="N.W"
                              onChange={(e) => handleNWChange(e, index)}
                              value={nw[index]}
                              id="nw"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              // onChange={(e) => handleGWChange(e, index)}
                              value={gw[index]}
                              id="gw"
                            />
                          </TableCell>
                          {/* <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            <input
                              required
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              // onChange={(e) => handleGWChange(e, index)}
                              value={total_gw}
                              id="gw"
                            />
                          </TableCell> */}
                          {/* <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            <input
                              required
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              // onChange={(e) => handleGWChange(e, index)}
                              value={amount[index]}
                              id="gw"
                            />
                          </TableCell> */}
                          {/* <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            <input
                              required
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              // onChange={(e) => handleGWChange(e, index)}
                              value={total_amount}
                              id="gw"
                            />
                          </TableCell> */}
                          {/* <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Status"
                              // onChange={(e) => handleStatusChange(e, index)}
                              value={amount[index]}
                              id="status"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Status"
                              onChange={(e) => handleStatusChange(e, index)}
                              value={status[index]}
                              id="status"
                            />
                          </TableCell> */}
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "8px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "150px", minWidth: "150px" }}
                              className="form-control"
                              name="name"
                              placeholder="Notes"
                              onChange={(e) => handleNotesChange(e, index)}
                              value={notes[index]}
                              id="notes"
                            />
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </SuiBox>
            {/* </div> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewContainer;
