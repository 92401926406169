/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Switch from "@mui/material/Switch";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { Box, Heading } from "rebass";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

function CardHome({ title, desc, image, images, id }) {
  const classes = useStyles();
  CardHome.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    images: PropTypes.instanceOf(Array).isRequired,
  };
  const navigate = useNavigate();
  return (
    <div>
      <div className="axil-img-container m-b-xs-15 m-b-sm-30">
        <a>
          {/* <a className="d-block"> */}

          <div
            className="image-list-item"
            style={{ height: "250px" }}
            onClick={() => navigate(`/single-post/${id}`)}
          >
            <img
              src={images[0].image}
              alt=""
              className="img-fluid"
              width="100%"
              height="390px"
              // style={{ height: "390" }}
            />
          </div>

          <div className="grad-overlay grad-overlay__transparent" />
          {/* </a> */}
        </a>
        <div className="media post-block grad-overlay position-absolute">
          <div className="media-body justify-content-end">
            <div
              className="post-cat-group m-b-xs-10"
              onClick={() => navigate(`/single-post/${id}`)}
            >
              <a>
                <a className={`post-cat cat-btn btn-mid bg-color-blue-one`}>{title}</a>
              </a>
            </div>
            <div className="axil-media-bottom" onClick={() => navigate(`/single-post/${id}`)}>
              <h4
                className=" hover-line m-b-xs-0"
                style={{ fontSize: "20px", color: "white", cursor: "pointer" }}
              >
                <a style={{ textDecoration: "none" }}>
                  {desc.length > 30 ? `${desc.substring(0, 30)}...` : desc}
                </a>
              </h4>
            </div>
          </div>
        </div>

        {/* End of .post-block */}
      </div>
    </div>
  );
}

export default CardHome;
