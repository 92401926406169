/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
// Authentication layout components
import { useTranslation } from "react-i18next";
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import LoginButton from "./login";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const { t } = useTranslation();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  return (
    <CoverLayout title={t("wel_back")} description={t("email_password_login")} image={curved9}>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t("email")}
            </SuiTypography>
          </SuiBox>
          <SuiInput type="email" placeholder="Email" id="email" />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              {t("password")}
            </SuiTypography>
          </SuiBox>
          <SuiInput type="password" placeholder="Password" id="password" />
        </SuiBox>

        <LoginButton />
        {/* <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox> */}
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
