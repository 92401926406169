import useDelete from "hooks/general/useDelete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useCustomers = () => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
    };
    // const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers", { headers });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setCustomers(data["customers"]);
    setloading(false);
  };
  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);
  const { dialogdelete, setDialogDelete, openDialog, closeDialog, executeDelete } = useDelete(
    "remove_customer",
    fetchData
  );
  const navigate = useNavigate();

  return {
    t,
    loading,
    customers,
    fetchData,
    navigate,
    dialogdelete,
    setDialogDelete,
    openDialog,
    closeDialog,
    executeDelete,
  };
};

export default useCustomers;
