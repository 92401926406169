/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { CSVLink } from "react-csv";
import "react-toastify/dist/ReactToastify.css";

function ContainersDetails() {
  const route = useLocation().pathname.split("/").slice(1);
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [price, setPrice] = useState([]);
  const [amount, setAmount] = useState();
  const [customer_id, setCustomer_id] = useState([""]);
  const [totalCTN, setTotalCTN] = useState(0);
  const [totalCBM, setTotalCBM] = useState(0);
  const [totalGW, setTotalGW] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/container_details/${route[route.length - 1]}`
    );
    const data = await response.json();

    const apiResponse = data["order_details"];
    const customerIdArray = apiResponse.map((obj) => obj.customer_id);
    setCustomer_id(customerIdArray);
    for (let i = 0; i < apiResponse.length; i += 1) {
      customer_id.push("");
    }
    const prices = apiResponse.map((obj) => obj.price || ""); // Get the price or set it to an empty string if not available
    setPrice(prices);
    // Adding data from the API response to the existing array
    apiResponse.forEach((obj) => {
      excelData.push(obj);
    });
    setUsers(apiResponse);
    setloading(false);
  };
  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);
  const getCsvData = (index) => {
    const csvData = [
      [
        "Customer Code",
        "Customer Name",
        "Phone Number",
        "Address",
        "Vat Name",
        "Total CTN",
        "Total CBM",
        "Total G/W",
        "price",
        "Amount",
      ],
    ];
    let i;
    for (i = 0; i < excelData.length; i += 1) {
      const calculatedAmount = price[i] * excelData[i].total_cbm;
      csvData.push([
        `${excelData[i].customer_id}`,
        `${excelData[i].customer_name}`,
        `${excelData[i].phone_number}`,
        `${excelData[i].address}`,
        `${excelData[i].vat_name}`,
        `${excelData[i].total_ctn}`,
        `${excelData[i].total_g_w}`,
        `${excelData[i].total_cbm}`,
        price[i],
        calculatedAmount,
      ]);
      // console.log(csvData, " csvData");
      // console.log(calculatedAmount, "amount");
      if (index === i) {
        setAmount(calculatedAmount);
      }
    }
    return csvData;
  };
  const updatePriceForCustomer = () => {
    const formData = new FormData();
    price.map((item, index) => formData.append(`prices[${index}]`, price[index]));
    customer_id.map((item, index) => formData.append(`customers[${index}]`, customer_id[index]));

    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/set_prices";

    axios
      .post(url, formData, headers)
      .then((response) => {
        toast.success("Successfully added price!");
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Failed to add the price!");
        console.log("error", error);
      });
  };

  useEffect(() => {
    // Calculate totals when users array changes
    let ctnTotal = 0;
    let cbmTotal = 0;
    let gwTotal = 0;
    let amountTotal = 0;

    users.forEach((user, index) => {
      ctnTotal += parseFloat(user.total_ctn) || 0;
      cbmTotal += parseFloat(user.total_cbm) || 0;
      gwTotal += parseFloat(user.total_g_w) || 0;
      amountTotal += price[index] * (parseFloat(user.total_cbm) || 0);
    });

    // Update state variables
    setTotalCTN(ctnTotal);
    setTotalCBM(cbmTotal);
    setTotalGW(gwTotal);
    setTotalAmount(amountTotal);
  }, [users, price]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbr /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ width: "200px", margin: "20px", color: "white" }}>
              <Button variant="contained" style={{ color: "white" }}>
                <CSVLink
                  filename="ContainersDetails.csv"
                  data={getCsvData()}
                  style={{ color: "white" }}
                >
                  Export To Excel
                </CSVLink>
              </Button>
            </div>
            {/* <button onClick={exportImagesToExcel}>Download Excel with Image</button> */}
            <div style={{ width: "200px", margin: "20px", color: "white" }}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={updatePriceForCustomer}
              >
                Add Price
              </Button>
              <ToastContainer />
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "" }}
                >
                  Total T_CTN
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  placeholder="Total CTN"
                  style={{ width: "70px", minWidth: "70px" }}
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={totalCTN}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "" }}
                >
                  Total T_G.w
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={totalGW}
                  style={{ width: "70px", minWidth: "70px" }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "" }}
                >
                  Total T_CBM
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={totalCBM}
                  style={{ width: "70px", minWidth: "70px" }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "" }}
                >
                  Total T_Amount
                </label>
                <input
                  required
                  id="shop_no2"
                  type="text"
                  className="form-control"
                  name="name"
                  readOnly
                  // onChange={(e) => setShopNumber2(e.target.value)}
                  value={totalAmount}
                  style={{ width: "70px", minWidth: "70px" }}
                />
              </div>
            </div>
          </div>

          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Containers Details</SuiTypography>
            </SuiBox>
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        C. Name
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        C. Code
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        P NO.
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Address{" "}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Vat name{" "}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        T/CTN
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        T/CBM
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        T/G_W
                      </TableCell>
                    </Box>

                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Price
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        T/Amount
                      </TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                {users && users.length > 0 ? (
                  <TableBody>
                    {users.map((user, index) => (
                      <TableRow
                        key={user.id}
                        style={{ marginLeft: "20px" }}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                        }}
                      >
                        <Box>
                          <TableCell
                            sx={{
                              width: window.innerWidth / 9,
                              // borderRight: "1px solid #e6e9ec",
                              // overflowWrap: "break-word",
                            }}
                            align="center"
                          >
                            {user.customer_name ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.customer_id ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.phone_number ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.address ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.vat_name ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_ctn).toFixed(2) ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_cbm).toFixed(2) ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_g_w).toFixed(2) ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            <input
                              required
                              type="text"
                              className="form-control"
                              name="notes"
                              value={price[index]}
                              onChange={(e) => {
                                const updatedPrice = [...price];
                                updatedPrice[index] = e.target.value;
                                setPrice(updatedPrice);
                                console.log(price, "price");
                              }}
                            />
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(price[index] * user.total_cbm).toFixed(2)}
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            )}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ContainersDetails;
