/* eslint-disable no-unused-vars */
import { useFilePicker } from "use-file-picker";
import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function ChooseFile() {
  const { t } = useTranslation();
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: ".txt",
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  function backUp() {
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    axios
      .post(
        "http://medicalsystem.test/api/database/backup/import",
        {
          sql_file: "file.name",
        },
        { headers }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Button
        onClick={() => openFileSelector()}
        variant="contained"
        style={{
          color: "white",
          width: 200,
          height: "150px",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginRight: "30px",
          marginLeft: "30px",
        }}
      >
        {t("choose_file")}
      </Button>
      {filesContent.map((file) => (
        <div>
          <h2>{file.name}</h2>
          {/* <div key={index}>{file.content}</div> */}
          <br />
        </div>
      ))}
    </>
  );
}
