/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import EditCustomerSuccess from "./edit_dialog";

function EditCustomer() {
  const route = useLocation().pathname.split("/").slice(1);
  const [users, setUsers] = useState([]);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [address, setAddress] = useState([]);
  const [code, setCode] = useState([]);
  const [user_id, setUserID] = useState([]);
  const [vatname, setVatName] = useState([]);
  const [id, setID] = useState([]);
  const getUsers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/users");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["users"]);
  };
  const fetchData = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_customer/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["customer"]["name"]);
    setPhone(data["customer"]["phone_number"]);
    setAddress(data["customer"]["address"]);
    setCode(data["customer"]["code"]);
    setVatName(data["customer"]["vat_name"]);
    setUserID(data["customer"]["user_id"]);
  };
  useEffect(() => {
    fetchData();
    getUsers();
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Customer</SuiTypography>
            </SuiBox>
            <div style={{ width: "400px", margin: "20px" }}>
              <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Code
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Code"
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Address
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Vat Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Vat Name"
                  onChange={(e) => setVatName(e.target.value)}
                  value={vatname}
                />
              </div>

              <div className="form-group  col-md-12">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px", color: "black" }}
                >
                  Choose User
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setUserID(e.target.value)}
                >
                  {users.map((user1) => (
                    <option value={user1.id}>{user1.name}</option>
                  ))}
                </select>
              </div>

              <EditCustomerSuccess
                name1={name}
                code1={code}
                address1={address}
                phone1={phone}
                vat_name1={vatname}
                user_id1={user_id}
              />
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCustomer;
