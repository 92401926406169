/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteSetting from "./delete_setting";

function Totals() {
  const [totals, setTotals] = useState([]);
  const { t } = useTranslation();
  const fetchData = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/totals");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setTotals(data["totals"]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Totals</SuiTypography>
            </SuiBox>
            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ display: "flex" }}>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 5 }} align="start">
                      ID
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                      Order Number
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                      User Number
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                      Balance
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                      Operations
                    </TableCell>
                  </Box>
                </TableRow>
              </TableHead>
              <TableBody>
                {totals.map((payment) => (
                  <TableRow
                    key={payment.name}
                    sx={{
                      // "&:last-child td, &:last-child th": { border: 0 },
                      display: "flex",
                    }}
                  >
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 5 }} align="start">
                        {payment.id ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                        {payment.name ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                        {payment.user_number ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                        {payment.balance ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 5 }} align="center">
                        <SuiTypography
                          component="a"
                          href="#"
                          variant="caption"
                          color="secondary"
                          fontWeight="medium"
                          onClick={() => {
                            navigate(`/edit_setting/${payment.id}`);
                          }}
                        >
                          {t("edit")}
                        </SuiTypography>
                        <DeleteSetting fetch={fetchData} id={payment.id} />
                      </TableCell>
                    </Box>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </TableContainer> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Totals;
