/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

export default function AlertDialog({
  name1,
  account_name1,
  debet1,
  credit1,
  currency1,
  val_currency1,
  e_credit1,
  e_debet1,
  balance1,
  totalbalance1,
  notes1,
}) {
  AlertDialog.propTypes = {
    totalbalance1: PropTypes.string.isRequired,
    name1: PropTypes.instanceOf(Array).isRequired,
    notes1: PropTypes.instanceOf(Array).isRequired,
    account_name1: PropTypes.instanceOf(Array).isRequired,
    debet1: PropTypes.instanceOf(Array).isRequired,
    credit1: PropTypes.instanceOf(Array).isRequired,
    currency1: PropTypes.instanceOf(Array).isRequired,
    val_currency1: PropTypes.instanceOf(Array).isRequired,
    e_debet1: PropTypes.instanceOf(Array).isRequired,
    e_credit1: PropTypes.instanceOf(Array).isRequired,
    balance1: PropTypes.instanceOf(Array).isRequired,
  };
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  function addUser() {
    const headers = { ContentType: `application/json` };
    axios
      .post(
        "https://trust-cargo.co/anas_backend/api/add_transaction",
        {
          order_number: name1,
          account_name: account_name1,
          debet: debet1,
          credit: credit1,
          currency: currency1,
          val_currency: val_currency1,
          e_debet: e_debet1,
          e_credit: e_credit1,
          balance: balance1,
          notes: notes1,
        },
        { headers }
      )
      .then((response) => {
        console.log(response);
        handleCloseLoading();
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="contained"
        style={{ margin: "20px", color: "white" }}
        onClick={() => {
          console.log("ppp");
          console.log(totalbalance1);
          handleClickOpenLoading();
          addUser();
          // if (totalbalance1 === "0") {
          //   handleCloseLoading();
          //   handleClickOpenEmpty();
          // } else {
          //   addUser();
          // }
        }}
      >
        Add Transaction
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم اضافه هذه الحركه بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/transactions");
            }}
            autoFocus
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">الرجاء التأكد من الرصيد </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            {t("ok!!")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
