/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import React from "react";
import Box from "@mui/material/Box";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

// import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
// import Select from "@mui/material/Select";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ChooseFile from "./file";

// import AlertDialog from "./dialog_success";

function BackUpData() {
  const { t } = useTranslation();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
              style={{ flexDirection: "column" }}
            >
              <SuiTypography variant="h4">{t("back_up")}</SuiTypography>
              <Box
                sx={{
                  height: "2px",
                  backgroundColor: "#DFE2E5",
                  width: "40rem",
                  marginTop: "10px",
                }}
              />
              <Box
                sx={{
                  // height: "10rem",
                  display: "flex",
                  // backgroundColor: "#DFE2E5",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <ChooseFile />
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    height: "150px",
                    width: 200,
                    marginRight: "30px",
                    marginLeft: "30px",
                  }}
                >
                  Add new patient
                </Button>
              </Box>
              <Box
                sx={{
                  height: "2px",
                  backgroundColor: "#DFE2E5",
                  width: "40rem",
                  marginTop: "10px",
                }}
              />
            </SuiBox>
          </Card>
        </SuiBox>
        <Card>
          <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SuiTypography variant="h6">Projects table</SuiTypography>
          </SuiBox>
          <SuiBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {/* <Table columns={prCols} rows={prRows} /> */}
          </SuiBox>
        </Card>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BackUpData;
